import React, { FC } from 'react';

type OrderStatusNotOwnerContentType = {
  isAuth: boolean
}

const OrderStatusNotOwnerContent:FC<OrderStatusNotOwnerContentType> = (props) => {
  const { isAuth } = props;
  const orderStatusTexts = window.orderStatusTexts || {};
  return (
    <>
      {isAuth
        ? (
          <div className="order-status_info-not-owner">
            <div className="order-status_info-not-owner_content">
              <div className="order-status_info-not-owner_text">
                {orderStatusTexts.orderIsNotOwnerText}
              </div>
              <div className="order-status_info-not-owner_img"/>
              <div className="order-status_info-not-owner_subtext">
                {orderStatusTexts.orderIsNotOwnerSubtitle}
              </div>
            </div>
          </div>
        ) : (
          <div className="order-status_info-text">
            <div className="order-status_info-text_content">
              {orderStatusTexts.notifyText}
              &nbsp;
              <span className="link js-auth-modal-show">
                {orderStatusTexts.notifyAuthLink}
              </span>
            </div>
          </div>
        )}
    </>
  )
};


export default OrderStatusNotOwnerContent;
