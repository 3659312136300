// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setTypes, setTypesLoading } from '../../slice';

export function* callPostTypesWorker(): SagaIterator {
  yield put(setTypesLoading(true));
  try {
    const response = yield call(axiosAPI.tradeIn.postTypes);
    yield put(
      setTypes({
        ...response?.data,
        data: response?.data?.data || [],
      }),
    );
  } catch (error) {
    console.warn(error);
  }
  yield put(setTypesLoading(false));
}
