// Core
import React, { FC } from "react";
import { useSelector } from 'react-redux';
// Parts
import { InfoForm } from './components/InfoForm'
import { OrderStatus } from './components/OrderStatus'
// Engine
import { selectorsOrderStatus } from '../../../../engine/core/orderStatus/selectors'

const Order: FC = () => {
  const data = useSelector(selectorsOrderStatus.data);
  return data?.orderNumber
      ? <OrderStatus />
      : <InfoForm />
}

export default Order;
