// Core
import React, { memo, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
// Engine
import { SelectorsTradeIn } from '../../../../../engine/core/tradeIn/selectors';
import {
  setGrade,
  gradeDefaultState,
} from '../../../../../engine/core/tradeIn/slice';
// Parts
import useStyles from './styles';
import Question from '../Question';
import { MutatorsTypes } from '../../../../components/_Form/types';
import { composeValidators, isRequired } from "../../../../components/_Form/validators";
import Select from "../../../../components/_Form/Select";
// TS
import { QuestionValues } from '../../../../../engine/core/tradeIn/types';
import { postLaptopItemsAsync } from "../../../../../engine/core/tradeIn/saga/asyncAction";

type questionGroup1Type = {
  title: string;
  questions: [
    {
      question: string;
      code: string;
      type: 'radio';
      answers: [
        {
          answer: string;
          value: number;
          image?: string;
        },
      ];
    },
  ];
}

type questionGroup2Type = MutatorsTypes & {
  child_type: number,
  id: string,
  title: string,
  type: number,
  values: { id: string, title: string }[]
}

const LaptopItem: FC<questionGroup2Type> = props => {
  const { id, child_type, values: valuesQuestions2, title, type, mutators } = props;
  const { gradeOutOfRange, price } = useSelector(SelectorsTradeIn.grade.date);
  const dispatch = useDispatch();
  const options = valuesQuestions2.map(option => ({ value: option.id, label: option.title }));
  const items = useSelector(SelectorsTradeIn.laptop.items.date)?.[type];
  const date = useSelector(SelectorsTradeIn.laptop.questions.date);
  const questionsData2 = date?.questions?.group2 ? date.questions.group2 : [];
  // @ts-ignore TODO
  const normalizeItems = Array.isArray(items) ? items.map((item: { title: string, id: string }) => ({
    label: item.title,
    value: item.id,
  })) : [];
  const disabled = (options.length === 0 && normalizeItems.length === 0)
    || (Boolean(gradeOutOfRange) && price !== 0)
    || (gradeOutOfRange === undefined && price === 0)
  return (
    <>
      <Field
        isSearchable
        validate={composeValidators(isRequired)}
        key={id}
        name={`group2_${id}`}
        component={Select}
        disabled={disabled}
        options={options.length === 0 && normalizeItems.length ? normalizeItems : options}
        label={title}
      />
      {child_type && (
        <OnChange name={`group2_${id}`}>
          {value => {
            questionsData2.forEach((item:questionGroup2Type) => {
              if (item.type === child_type) {
                mutators.changeValue(`group2_${item.id}`, null);
              }
            })
            dispatch(postLaptopItemsAsync({
              parent: parseInt(value.value, 10),
              childId: child_type
            }))
          }}
        </OnChange>
      )}
    </>
  )
}

const QuestionsLaptop: FC<MutatorsTypes & QuestionValues> = props => {
  const { values, mutators } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const date = useSelector(SelectorsTradeIn.laptop.questions.date);
  const loading = useSelector(SelectorsTradeIn.laptop.questions.loading);
  const questionsData = { group1: date?.questions?.group1 };
  const questionsData2 = date?.questions?.group2 ? date.questions.group2 : [];
  // @ts-ignore
  const questionsLists = questionsData ? Object.keys(questionsData).map(key => questionsData[key]) : [];

  useEffect(() => {
    dispatch(setGrade(gradeDefaultState));
  }, [dispatch]);

  if (loading === true) {
    return null;
  }

  return (
    <>
      {Boolean(questionsLists.length) &&
        questionsLists.map(
          (questionsListsItem: questionGroup1Type) => (
            <div key={questionsListsItem.title} className={classes.questions}>
              <h6 className={classes.questionsTitle}>{questionsListsItem.title}</h6>
              {questionsListsItem.questions.map(question => (
                <Question
                  mutators={mutators}
                  values={values}
                  key={question.code}
                  question={question.question}
                  answers={question.answers}
                  code={question.code}
                />
              ))}
            </div>
          ),
        )}
      {Boolean(questionsData2.length) &&
        questionsData2.map((questionsData2Item: questionGroup2Type) =>
          <LaptopItem
            mutators={mutators}
            key={questionsData2Item.id}
            type={questionsData2Item.type}
            id={questionsData2Item.id}
            values={questionsData2Item.values}
            child_type={questionsData2Item.child_type}
            title={questionsData2Item.title}
        />)
      }
    </>
  );
};

export default memo(QuestionsLaptop);
