// Core
import React, { Component, FC } from 'react';
import diff from 'object-diff';
import { FormSpy } from 'react-final-form';

type SpyTypes = {
  debounce: number;
  save: (values: unknown) => void | undefined;
}

type AutoSaveProps = SpyTypes & {
  values: { [key: string]: unknown };
  errors: { [key: string]: unknown };
};
type AutoSaveState = {
  values: unknown;
  submitting: boolean;
};

class AutoSave extends Component<AutoSaveProps, AutoSaveState> {
  promise: void | undefined;

  timeout: NodeJS.Timeout | undefined;

  constructor(props: AutoSaveProps) {
    super(props);
    this.state = {
      values: props.values,
      submitting: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: AutoSaveProps) {
    const { debounce } = this.props;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const { values, save, errors } = this.props;
    const { values: valuesFormState } = this.state;

    const difference = diff(valuesFormState, values);
    if (Object.keys(difference).length && (Object.keys(errors).length === 0)) {
      this.setState({ submitting: true, values });
      this.promise = save(values);
      await this.promise;
      delete this.promise;
      this.setState({ submitting: false });
    }
  };

  render() {
    const { submitting } = this.state;
    return submitting && 'загрузка...';
  }
}

const Spy: FC<SpyTypes> = props => {
  const { debounce, save } = props;
  return (
    <FormSpy
      debounce={debounce}
      save={save}
      subscription={{ values: true, errors: true }}
      // @ts-ignore
      component={AutoSave}
    />
  );
};
export default Spy;
