// Parts
import { createAction } from '@reduxjs/toolkit';

export type postBrandsAsyncTypes = {
  typeId: string;
  limit: number;
  offset: string;
};
export type postModelsAsyncTypes = {
  query: string;
  type: string;
  brand: string;
  limit: number;
};
export type postQuestionsAsyncTypes = {
  type: string;
};

export type postGradeAsyncTypes = {
  answers: object;
  product_id: number | string;
};

export type postLaptopItemsAsyncTypes = {
  parent: number;
  childId: number;
};
const tradeInAsyncAction = Object.freeze({
  postTypesAsync: createAction('GET_TYPES_ASYNC'),
  postBrandsAsync: createAction<postBrandsAsyncTypes>('POST_TRADE_IN_BRANDS_ASYNC'),
  postModelsAsync: createAction<postModelsAsyncTypes>('POST_TRADE_IN_MODELS_ASYNC'),
  postQuestionsAsync: createAction<postQuestionsAsyncTypes>('POST_TRADE_IN_QUESTIONS_ASYNC'),
  postGradeAsync: createAction<postGradeAsyncTypes>('POST_TRADE_IN_GRADE_ASYNC'),
  postLaptopItemsAsync: createAction<postLaptopItemsAsyncTypes>('POST_TRADE_IN_LAPTOP_ITEMS_ASYNC'),
});

export const {
  postTypesAsync, postBrandsAsync,
  postModelsAsync, postQuestionsAsync,
  postGradeAsync, postLaptopItemsAsync
} = tradeInAsyncAction;
