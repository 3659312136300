// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call } from 'redux-saga/effects';
// Watchers
import { watchersTradeIn } from '../core/tradeIn/saga/watcher';
import { watchersOrderStatus } from '../core/orderStatus/saga/watcher';
import { watchersRepairStatus } from '../core/repairStatus/saga/watcher';

export function* rootSaga(): SagaIterator {
  yield all([
    call(watchersTradeIn),
    call(watchersOrderStatus),
    call(watchersRepairStatus)
  ]);
}
