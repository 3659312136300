// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import load from '../../assets/icons/load.svg';
import { $colors, $textColors } from "../../../engine/constants/theme";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: "relative",
    cursor: 'pointer',
    color: '#fff',
    borderRadius: 48,
    textTransform: 'initial',
    height: 52,
    fontSize: 18,
    marginTop: '16px',
    backgroundColor: $colors.$main,
    transition: '.3s ease',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: $colors.$mainHover
    }
  },
  secondary: {
    "display": "inline-flex",
    "alignItems": "center",
    "justifyContent": "center",
    "textAlign": "center",
    "color": $textColors.$dark,
    "fontWeight": "700",
    "borderRadius": "52px",
    "fontSize": "16px",
    "padding": "0 18px",
    "transition": ".3s ease",
    [theme.breakpoints.up('md')]: {
      "fontSize": "18px",
      "padding": "0 32px",
    },
    backgroundColor: $colors.$secondary,
    '&:hover': {
      backgroundColor: $colors.$secondaryHover,
    }
  },
  fullWidth: {
    width: '100%',
  },
  loading: {
    cursor: "default",
    pointerEvents: 'none',
  },
  disabled: {
    color: '#fff',
    backgroundColor: $colors.$disable,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: $colors.$disable
    }
  },
  loader: {
    backgroundImage: `url(${load})`,
    backgroundSize: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    margin: "0 auto",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    animation: "$spinIcon .6s ease-in-out infinite"
  },
  "@keyframes spinIcon": {
    "0%": { transform: "rotate(360deg)" },
    to: { transform: "rotate(0deg)" }
  }
}));

export default useStyles;
