// Engine
import { RootState } from '../../init/store';

export const selectorsOrderStatus = Object.freeze({
  loading: (state: RootState) => state.orderStatus.loading,
  status: (state: RootState) => state.orderStatus.status,
  notify: (state: RootState) => state.orderStatus.notify,
  data: (state: RootState) => state.orderStatus.data,
  error: (state: RootState) => state.orderStatus.error,
  clearValue: (state: RootState) => state.orderStatus.clearValue,
  showCancelOrderModal: (state: RootState) => state.orderStatus.showCancelOrderModal,
  canceledUserOrder: {
    loading: (state: RootState) => state.orderStatus.canceledUserOrder.loading,
    informText: (state: RootState) => state.orderStatus.canceledUserOrder.informText,
  },
  questionsForm: {
    loading: (state: RootState) => state.orderStatus.questionsForm.loading,
    successText: (state: RootState) => state.orderStatus.questionsForm.successText,
    errorText: (state: RootState) => state.orderStatus.questionsForm.errorText,
  },
  leads: {
    loading: (state: RootState) => state.orderStatus.leads.loading,
    successText: (state: RootState) => state.orderStatus.leads.successText,
    errorText: (state: RootState) => state.orderStatus.leads.errorText,
  }
});
