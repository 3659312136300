let eventsInstance = null;

function fireEvent(event, data) {
  let isDo = true;
  if (this.events) {
    const eventData = this.events[event];

    if (eventData) {
      for (let key in eventData) {
        if (isDo && Array.isArray(eventData[key])) {
          for (let i = 0; i < eventData[key].length; i++) {
            let res;
            let fn = eventData[key][i];
            if (isDo && typeof fn === 'function') {
              res = fn.apply(this, [data]);
              isDo = res === true || typeof res === 'undefined';
            }
          }
        }
      }
    }
  }
}

export default class Events {
  constructor() {
    if (!eventsInstance && window.app) {
      eventsInstance = window.app;
    }

    if (!eventsInstance) {
      eventsInstance = this;
      window.app = this;
    }

    this.events = {};

    return eventsInstance;
  }

  subscribe(event, callBack) {
    if (typeof event === 'string') {
      if (/\./.test(event)) {
        event = event.split('.');
        if (!this.events[event[0]]) {
          this.events[event[0]] = {
            all: [],
          };
          this.events[event[0]][event[1]] = [];
        }
        this.events[event[0]][event[1]].push(callBack);
      } else {
        if (!this.events[event]) {
          this.events[event] = {
            all: [],
          };
        }
        this.events[event].all.push(callBack);
      }
    }
    return this;
  }

  unsubscribe(event) {
    if (/\./.test(event)) {
      event = event.split('.');
      if (this.events[event[0]]) {
        if (this.events[event[0]][event[1]]) {
          this.events[event[0]][event[1]] = [];
        }
      }
    } else {
      if (this.events[event] && this.events[event].all) {
        this.events[event].all = [];
      }
    }
    return this;
  }

  callEvent(event, data) {
    if (Array.isArray(event)) {
      event.forEach((el) => {
        fireEvent.apply(this, [el, data]);
      });
    } else {
      fireEvent.apply(this, [event, data]);
    }

    return this;
  }

  get eventsList() {
    return this.events;
  }
}
