// Core
import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SvgIcon, Skeleton } from '@mui/material';
// Engine
import { SelectorsTradeIn } from '../../../../../engine/core/tradeIn/selectors';
import { ErrorsTypes, ErrorsCountTypes } from '../../../../components/_Form/types';
import useStyles from './styles';
import { PriceTypes, GradeOutOfRangeTypes, ShowGradeOutOfRangeTypes } from '../../../../../engine/core/tradeIn/types';

const InfoText = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <SvgIcon className={classes.proposeSvg} width="9" height="8" viewBox="0 0 9 8" fill="none">
        <path
          d="M3.22281 4.46753L0 3.61747L0.490891 2.19126L3.7137 3.24911L3.61765 0H5.2504L5.14368 3.29634L8.31313 2.25738L8.80402 3.69303L5.52786 4.55254L7.64082 7.11216L6.31755 8L4.33264 5.27981L2.41177 7.93388L1.07783 7.07438L3.22281 4.46753Z"
          fill="#777E90"
        />
      </SvgIcon>
      <p className={classes.proposeSubtitle}>{t('tradeIn.propose.thisIsAnEstimate')}</p>
    </>
  );
};

type NotAllParamsProps = {
  errorsCount: ErrorsCountTypes;
};

const NotAllParams: FC<NotAllParamsProps> = props => {
  const { errorsCount } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(SelectorsTradeIn.grade.loading);

  return (
    <>
      <b className={classes.proposePrice}>
        {loading && errorsCount === 0 ? <Skeleton height={40} /> : t('tradeIn.propose.notAllOptionsSelected')}
      </b>
      <InfoText />
    </>
  );
};

type HavePriceProps = {
  price: PriceTypes;
  showGradeOutOfRange: ShowGradeOutOfRangeTypes;
  gradeOutOfRange: GradeOutOfRangeTypes;
};

const HavePrice: FC<HavePriceProps> = props => {
  const { price, showGradeOutOfRange, gradeOutOfRange } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const loading = useSelector(SelectorsTradeIn.grade.loading);
  return (typeof price === 'string' && price !== '0') || loading ? (
    <>
      <b className={classes.proposePrice}>{loading ? <Skeleton height={40} /> : <>{price} грн</>}</b>
      <InfoText />
    </>
  ) : (
    <>
      <p className={classes.proposeInfo}>{t('tradeIn.propose.weCanRedeem')}</p>
      <p className={classes.proposeInfo}>
        {' '}
        {showGradeOutOfRange && <b>{t('tradeIn.propose.getCertificate', { gradeOutOfRange })}</b>}
      </p>
    </>
  );
};

const Propose: FC<ErrorsTypes> = props => {
  const { errors } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const errorsCount = errors ? Object.keys(errors).length : 0;
  const data = useSelector(SelectorsTradeIn.grade.date);
  const loading = useSelector(SelectorsTradeIn.grade.loading);
  const showGradeOutOfRange = Boolean(data.isGradeOutOfRange && data.gradeOutOfRange !== '');

  const Content = () =>
    (data.price !== 0 &&
    ((errorsCount || data.price === null || loading) && !showGradeOutOfRange)
    ) ? (
      <NotAllParams errorsCount={errorsCount} />
    ) : (
      <HavePrice price={data.price} showGradeOutOfRange={showGradeOutOfRange} gradeOutOfRange={data.gradeOutOfRange} />
    );

  return (
    <div className={classes.propose}>
      <h4 className={classes.proposeTitle}>{t('tradeIn.propose.title')}</h4>
      <Content />
    </div>
  );
};

export default memo(Propose);
