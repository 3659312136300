// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 !important',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '0 !important',
    },
  },
  dialogContent: {
    padding: '26px 16px !important',
    [theme.breakpoints.up('lg')]: {
      padding: '20px 40px 40px !important',
    },
  },
  title: {
    display: 'block',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 24,
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
  },
}));

export default useStyles;
