import React from 'react';
import ReactDOM from 'react-dom';

// Parts
import App from './ui/containers/App';
import reportWebVitals from './reportWebVitals';
import CheckoutApp from "./ui/portals/Checkout/react-app";

interface StatusForms {
  mutators?: { [key: string]: (name: string, value: unknown) => void };
}

declare global {
  interface Window {
    app: {
      callEvent: (event: string) => void;
      subscribe: (event: string) => void;
    };
    orderStatusTexts: { [key: string]: string };
    orderForm?: StatusForms;
    repairForm?: StatusForms;
  }
}

const rootContainer = document.getElementById('react-root-app');

if (rootContainer) {
  ReactDOM.render(<div>
    <App />
    <CheckoutApp />
  </div>, rootContainer);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
