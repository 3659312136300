import * as actionTypes from "../actions/actionTypes";

const addModalId = (state, modalId) => {

    if ( !state.activeModalListId.includes(modalId) ) {
        return [...state.activeModalListId, modalId];
    } else {
        return [...state.activeModalListId];
    }
    
}

const initialState = {
    activeModalId: null,
    activeModalListId: [],
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_MODAL_SHOW:
            return {
                ...state,
                activeModalId: action.payload,
            };

        case actionTypes.MODAL_SHOW: 
            return {
                ...state,
                activeModalListId: addModalId(state, action.payload),
            }
        case actionTypes.MODAL_HIDE: 
            return {
                ...state,
                activeModalListId: state.activeModalListId.filter(id => id !== action.payload),
            }

        case actionTypes.SET_MODAL_DATA:
            return {
                ...state,
                ...{
                    [action.payload.modalId]: {
                        ...state[action.payload.modalId],
                        ...action.payload.data,
                    },
                },
            };

        case actionTypes.RESET_MODAL:
            return {
                ...state,
                ...{
                    [action.payload.modalId]: null,
                },
            }

        default:
            return state;
    }
};

export default modalReducer;