// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setLoading, setData, setError, setStatus, setNotify } from '../../slice';
// TS
import { postOrderStatusAsyncTypes } from '../asyncAction';

export function* callPostOrderStatusWorker(action: PayloadAction<postOrderStatusAsyncTypes>): SagaIterator {
  yield put(setLoading(true));
  try {
    const response = yield call(axiosAPI.basket.postStatus, action.payload);
    const { data } = response;
    if (response.status === 200 && typeof data.error !== 'string') {
      const newUrl = `${window.location.origin + window.location.pathname  }?orderId=${action.payload['Order[orderNumber]']}`;
      window.history.replaceState( {} , '', newUrl );
    }
    yield put(setData(data.data || {}));
    yield put(setError(data.error));
    yield put(setStatus(data.notify));
    yield put(setNotify(data.status));
  } catch (error) {
    console.warn(error);
  }
  yield put(setLoading(false));
}
