// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { $textColors } from "../../../../engine/constants/theme";

export const inputStyles = makeStyles((theme: Theme) => ({
  root: {
    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
      WebkitBoxShadow: "0 0 0 30px white inset !important"
    },
    '& .MuiInputLabel-root': {
      pointerEvents: 'none',
      color: '#777e90 !important',
      '&:not(.Mui-focused)': {
        transform: 'translate(20px, 16px) scale(1)',
      },
      '&.Mui-focused, &.MuiFormLabel-filled': {
        transform: 'translate(19px, 7px) scale(0.75)',
      },
    },
    '& .MuiFilledInput-root': {
      borderRadius: 48,
      border: '1px solid #d4d4d4',
      backgroundColor: '#fff !important',
      overflow: 'hidden',
      '&::after, &::before': {
        content: 'none',
      },
      '& .MuiFilledInput-input': {
        height: 19,
        paddingLeft: 18,
        paddingRight: 18,
        borderRadius: 0,
      },
    },
  },
  rootAdornmentLabel: {
    '& .MuiInputAdornment-root': {
      marginRight: 0,
      marginTop: '17px !important',
      pointerEvents: 'none',
      opacity: 0,
      visibility: 'hidden',
      paddingLeft: 6,
      '& .MuiTypography-root': {
        color: $textColors.$darkSecondary
      }
    },
    '& .MuiInputBase-input': {
      paddingLeft: '0 !important',
    },
    '& .MuiFormLabel-filled, & .Mui-focused': {
      '& .MuiInputAdornment-root, & + .MuiInputBase-adornedStart .MuiInputAdornment-root': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  rootMultiline: {
    '& .MuiFilledInput-root': {
      borderRadius: 24,
      '& .MuiFilledInput-input': {
        paddingLeft: 6,
        paddingRight: 6,
        borderRadius: 0,
      },
    },
  },
  rootError: {
    '& .MuiInputLabel-root': {
      color: '#fd3321 !important',
    },
    '& .MuiFilledInput-root': {
      borderColor: '#fd3321',
    }
  }
}));
