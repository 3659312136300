// Core
import { combineReducers } from '@reduxjs/toolkit';
// Instruments
import tradeIn from '../core/tradeIn/slice';
import orderStatus from '../core/orderStatus/slice';
import repairStatus from '../core/repairStatus/slice';

const rootReducer = combineReducers({
  tradeIn,
  orderStatus,
  repairStatus
});

export { rootReducer };
