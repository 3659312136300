// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import {
  setCanceledUserOrderLoading,
  setCanceledUserOrderInformText
} from '../../slice';
// TS
import { postCanceledUserOrderAsyncTypes } from '../asyncAction';

export function* callPostCanceledUserOrderWorker(action: PayloadAction<postCanceledUserOrderAsyncTypes>): SagaIterator {
  yield put(setCanceledUserOrderLoading(true));
  try {
    const response = yield call(axiosAPI.users.cancelledUserOrder, action.payload);
    if (response.data) {
      const responseData = response.data || {};
      if (responseData.data) {
        yield put(setCanceledUserOrderInformText(responseData.data))
      }
    }
  } catch (error) {
    console.warn(error);
  }
  yield put(setCanceledUserOrderLoading(false));
}
