// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setLaptopItems, setLaptopItemsLoading } from '../../slice';
import { SelectorsTradeIn } from '../../selectors';
import { postLaptopItemsAsyncTypes } from '../asyncAction';

export function* callPostLaptopItemsWorker(action: PayloadAction<postLaptopItemsAsyncTypes>): SagaIterator {
  yield put(setLaptopItemsLoading(true));
  const { childId, parent } = action.payload;
  try {
    const { data } = yield call(axiosAPI.tradeIn.laptop.postItems, { parent });
    const laptopItemsDate = yield select(SelectorsTradeIn.laptop.items.date);
    yield put(setLaptopItems({
      ...laptopItemsDate,
      [childId]: data.data
    }));
  } catch (error) {
    console.warn(error);
  }
  yield put(setLaptopItemsLoading(false));
}
