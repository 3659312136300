// Core
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import repairIdImg from './repairIdImg.png';
import Modal from '../../../../../components/Modal';
// Engine
import { selectorsRepairStatus } from '../../../../../../engine/core/repairStatus/selectors';
import { setShowIdPlaceModal } from '../../../../../../engine/core/repairStatus/slice';

const IdPlaceModal:FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const title = t('repairStatus.infoForm.findNumberLink');
  const showIdPlaceModal = useSelector(selectorsRepairStatus.showIdPlaceModal);

  const handleClose = () => {
    dispatch(setShowIdPlaceModal(false));
  };

  return (
    <Modal open={showIdPlaceModal} onClose={handleClose} title={title}>
      <img src={repairIdImg} alt={title}/>
    </Modal>
  );
}

export default IdPlaceModal;
