// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import {
  setQuestionsFormSuccessText,
  setQuestionsFormErrorText,
  setQuestionsFormLoading
} from '../../slice';
// TS
import { postOrderStatusQuestionFormAsyncTypes } from '../asyncAction';

export function* callPostOrderStatusQuestionsFormWorker(action: PayloadAction<postOrderStatusQuestionFormAsyncTypes>): SagaIterator {
  yield put(setQuestionsFormLoading(true));
  yield put(setQuestionsFormErrorText(undefined))
  try {
    const response = yield call(axiosAPI.sendMailOrderStatus, action.payload);
    if (response.data) {
      const { data, error } = response.data;
      if (error) {
        yield put(setQuestionsFormErrorText(error))
      } else if (data) {
        yield put(setQuestionsFormSuccessText(data))
      }
    }
  } catch (error) {
    console.warn(error);
  }
  yield put(setQuestionsFormLoading(false));
}
