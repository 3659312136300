// Core
import React, { FC, FormEvent } from 'react';
import { Radio as RadioMUI } from '@mui/material';
import { FieldRenderProps } from 'react-final-form';
import clsx from 'clsx';
// Parts
import { radioStyles } from './styles';

export type RadioTypes = FieldRenderProps<string> & {
  label?: string;
  labelType?: 'span' | 'label';
  disabled?: boolean;
  onChangeExtension?: (e: FormEvent<HTMLInputElement>) => unknown;
};

const Radio: FC<RadioTypes> = props => {
  const { label, labelType, input, disabled, onChangeExtension } = props;
  const classes = radioStyles();

  const radioClasses = clsx(classes.wrapper, {
    [classes.disabled]: disabled,
    [classes.cursorPointer]: labelType === 'label',
  });

  const onChange = (e: FormEvent<HTMLInputElement>) => {
    input.onChange(e);
    if (onChangeExtension) {
      onChangeExtension(e);
    }
  };

  const Component = <>
    <RadioMUI
      disableRipple
      classes={{
        root: classes.root,
      }}
      disabled={disabled}
      checked={input.checked}
      name={input.name}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={onChange}
      value={input.value}
    />
    <span className={classes.label}>{label}</span>
  </>

  switch (labelType) {
    case 'span': {
      return (
        <span className={radioClasses}>
          {Component}
        </span>
      )
    }
    default: {
      return (
        <label className={radioClasses}>
          {Component}
        </label>
      )
    }
  }
};

export default Radio;
