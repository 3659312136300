import * as types from "../actions/actionTypes";

const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SERVICES_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };

        case types.TOGGLE_SERVICE:
            return {
                ...state,
                ...{
                    groups: state.groups.map(group => {
                        if (group.id === action.payload.groupId) {
                            group.checked = !group.checked;
                        }
                        return group;
                    }),
                },
            };

        case types.SELECT_SERVICE:
            return {
                ...state,
                ...{
                    groups: state.groups.map(group => {
                        if (group.id === action.payload.groupId) {
                            group.products = group.products.map(product => {
                                product.selected = product.productId === action.payload.productId;
                                return product;
                            });
                        }
                        return group;
                    }),
                }
            };

        default:
            return state;
    }
};

const initialState = null;

export default servicesReducer;