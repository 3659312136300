export const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
};

const allowedCardNetworks = process.env.REACT_APP_ALLOWED_CARD_NETWORKS.split('/');
const allowedCardAuthMethods = process.env.REACT_APP_ALLOWED_CARD_AUTH_METHODS.split('/');

const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
        'gateway': process.env.REACT_APP_GATEWAY,
        'gatewayMerchantId': process.env.REACT_APP_GATEWAY_MERCHANT_ID,
    }
};

const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
        allowedAuthMethods: allowedCardAuthMethods,
        allowedCardNetworks
    }
};

export const allowedPaymentMethods = {

    ...baseCardPaymentMethod,
    tokenizationSpecification
};

export const isReadyToPayRequest = {
    ...baseRequest,
    allowedPaymentMethods: [baseCardPaymentMethod],
};
