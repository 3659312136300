import * as actionTypes from "./actionTypes";
import { axiosAPI } from "../../../../engine/config/axios";
import {isReadyToPayRequest} from "../google-pay/googlePaySettings";

// synchronous action creators
export function getCheckoutInitialsSuccess(data) {
    return {
        type: actionTypes.GET_CHECKOUT_INITIALS_SUCCESS,
        payload: data,
    };
}

export function getValidationTextsSuccess(data) {
    return {
        type: actionTypes.GET_VALIDATION_TEXTS_SUCCESS,
        payload: data,
    };
}

// thunk action creators
export function getCheckoutInitials() {
    return function (dispatch) {
        return axiosAPI.checkout.getCheckoutInitials()
            .then(({data: response}) => {
                if (response.status) {
                    dispatch(getCheckoutInitialsSuccess(response.data));
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function getValidationTexts() {
    return function (dispatch) {
        return axiosAPI.assets.getValidationTexts()
            .then(({data: response}) => {
                if (response.status) {
                    dispatch(getValidationTextsSuccess({validationTexts: response.data.texts}));
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function setDeviceType(data) {
    return {
        type: actionTypes.SET_DEVICE_TYPE,
        payload: data,
    };
}

export function checkIsGooglePayPosible() {
    return function (dispatch) {
        const paymentsClient = new google.payments.api.PaymentsClient({environment: process.env.REACT_APP_ENVIRONMENT});
        return paymentsClient.isReadyToPay(isReadyToPayRequest)
            .then((response) => {
                response.result && dispatch(getCheckoutInitialsSuccess({gpay: 1}));
            })
            .catch((err) => {
                // show error in developer console for debugging
                console.error('err', err);
            });
    }
}
