// Core
import React, { FC } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Parts
import Modal from "../../../../components/Modal";
// Engine
import { selectorsOrderStatus } from "../../../../../engine/core/orderStatus/selectors";
import { setShowCancelOrderModal } from "../../../../../engine/core/orderStatus/slice";
import { postCanceledUserOrderAsync } from "../../../../../engine/core/orderStatus/saga/asyncAction";

const CancelOrderModal:FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showCancelOrderModal = useSelector(selectorsOrderStatus.showCancelOrderModal);
  const orderData = useSelector(selectorsOrderStatus.data);
  const title = orderData?.texts?.cancelOrder;

  const CancelOrder = () => {
    if (orderData.orderNumber) {
      dispatch(postCanceledUserOrderAsync({
        order_id: orderData.orderNumber
      }));
      dispatch(setShowCancelOrderModal(false));
    }
  }

  const handleClose = () => {
    dispatch(setShowCancelOrderModal(false));
  };

  return (
    <Modal open={showCancelOrderModal} onClose={handleClose} title={title}>
      <div className="order-status_cancel-order_modal">
        <p className="order-status_cancel-order_text">{t('orderStatus.cancel.order.confirm')}</p>
        <button
          type="button"
          className="order-status_cancel-order_confirm"
          onClick={CancelOrder}
        >
          {t('yes')}
        </button>
        <button
          type="button"
          className="order-status_cancel-order_reject"
          onClick={handleClose}
        >
          {t('no')}
        </button>
      </div>
    </Modal>
  );
}

export default CancelOrderModal;
