// Core
import React, { FC } from "react";
import { OrderStatusItemType, OrderStatusItemAdditionalItemsType } from '../../../../../engine/core/orderStatus/slice'

const OrderStatusItemAdditionalItems:FC<OrderStatusItemAdditionalItemsType> = (props) => {
  const { name, price } = props;
  return <div className="order-status_item_additional_item">
    <div className="order-status_item_additional_item_name">
      {name}
    </div>
    <div
      className="order-status_item_additional_item_price"
      dangerouslySetInnerHTML={{ __html: price }}
    />
  </div>
};

const OrderStatusItem:FC<OrderStatusItemType> = (props) => {
  const {
    img, name, url, oldPrice, price,
    count, totalPrice, services, orderDataTexts
  } = props;

  return <div className="order-status_item">
    <div className="order-status_item_row">
      <div className="order-status_item_img">
        <img src={img} alt={name} />
      </div>
      <div className="order-status_item_info">
        <div className="order-status_item_name">
          {Boolean(url) && (
            <a href={url} target="_blank" rel="noreferrer">
              {name}
            </a>
          )}
          {!Boolean(url) && name}
        </div>
        <div className="order-status_item_details">
          <div className="order-status_item_details_price">
            {oldPrice && (
              <div className="order-status_item_details_price_old">
                {oldPrice}
              </div>
            )}
            <div
              className={`order-status_item_details_price_current ${oldPrice ? 'new-price' : ''}`}
              dangerouslySetInnerHTML={{ __html: price }}
            />
          </div>
          <div className="order-status_item_details_section">
            <div className="order-status_item_details_count">
              {count} шт.
            </div>
            <div
              className="order-status_item_details_total"
              dangerouslySetInnerHTML={{ __html: totalPrice }}
            />
          </div>
        </div>
      </div>
    </div>
    {Boolean(services.length) && (
      <div className="order-status_item_additional">
        {orderDataTexts?.additionalServices && (
          <div className="order-status_item_additional_title">
            {orderDataTexts.additionalServices}
          </div>
        )}
        <div className="order-status_item_additional_items">
          {services.map((service) => {
            const { name: serviceName, price: servicePrice, id } = service;
            return <OrderStatusItemAdditionalItems key={id} name={serviceName} price={servicePrice} />;
          })}
        </div>
      </div>
    )}
  </div>
}

export default OrderStatusItem;
