// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import {
  setOrderStatusLeadsSuccessText,
  setOrderStatusLeadsErrorText,
  setOrderStatusLeadsLoading,
} from '../../slice';
// TS
import { postOrderStatusLeadsCreateTypes } from '../asyncAction';

export function* callPostOrderStatusLeadsCreateWorker(action: PayloadAction<postOrderStatusLeadsCreateTypes>): SagaIterator {
  yield put(setOrderStatusLeadsLoading(true));
  try {
    const response = yield call(axiosAPI.orderStatusLeads.create, action.payload);
    const { notify, error } = response.data || {};

    yield put(setOrderStatusLeadsSuccessText(notify));
    yield put(setOrderStatusLeadsErrorText(error?.form?.email?.[0]));
  } catch (error) {
    console.warn(error);
  }
  yield put(setOrderStatusLeadsLoading(false));
}
