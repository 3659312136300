import { BONUSES } from "./actionTypes";
import { axiosAPI } from "../../../../engine/config/axios";

export const setBonuses = payload => ({ type: BONUSES.SET, payload });
export const userVerificationSuccess = payload => ({ type: BONUSES.USER_VERIFICATION_SUCCESS, payload });
export const userVerificationError = payload => ({ type: BONUSES.USER_VERIFICATION_ERROR, payload });
export const phoneVerifySuccess = payload => ({ type: BONUSES.PHONE_VERIFY_SUCCESS, payload });
export const phoneVerifyError = payload => ({ type: BONUSES.PHONE_VERIFY_ERROR, payload });
export const resendSMSSuccess = payload => ({ type: BONUSES.RESEND_SMS_SUCCESS, payload });
export const resendSMSError = payload => ({ type: BONUSES.RESEND_SMS_ERROR, payload });
export const toggleWaitingRequest = payload => ({ type: BONUSES.TOGGLE_WAITING_REQUEST, payload });
export const clearData = () => ({ type: BONUSES.CLEAR_DATA });
export const getBonusesValueSuccess = payload => ({ type: BONUSES.GET_USER_VALUE_SUCCESS, payload });
export const toggleBonusesLoader = (payload = false) => ({ type: BONUSES.TOGGLE_BONUSES_LOADER, payload });


export const getBonusesAsync = data => dispatch => {
    return axiosAPI.bonuses.getBonusesData(data)
        .then( ({data: response}) => {
            if (response.status) {
                dispatch(setBonuses(response.data));
                return response.data
            }
        })
        .catch( error => { throw error });
};

export function getUserVerification(dataJson) {
    return function(dispatch) {
        dispatch(toggleWaitingRequest(true));
        return axiosAPI.bonuses.userVerification(dataJson)
            .then( ({data: response}) => {
                if (response.status) {
                    dispatch(userVerificationSuccess(response.data));
                } else {
                    dispatch(userVerificationError(response.error));
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function phoneVerify(dataJson) {
    return function(dispatch) {
        dispatch(toggleWaitingRequest(true));
        return axiosAPI.bonuses.phoneVerify(dataJson)
            .then( ({data: response}) => {
                if (response.status) {
                    dispatch(phoneVerifySuccess(response.data));
                } else {
                    if (typeof response.error == "string") {
                        //dispatch error not available
                        dispatch(phoneVerifyError({isNotAvailable: true}));
                    } else {
                        //dispatch default error
                        dispatch(phoneVerifyError({smsError: response.error.smsCode[0]}));
                    }
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function resendSMS(dataJson) {
    return function(dispatch) {
        dispatch(toggleWaitingRequest(true));
        return axiosAPI.bonuses.resendSMS(dataJson)
            .then( ({data: response}) => {
                if (response.status) {
                    dispatch(resendSMSSuccess(response.data));
                } else {
                    dispatch(resendSMSError({smsError:response.error.phone}));
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function getBonusesValue(dataJson) {
  return function(dispatch) {
    dispatch(toggleWaitingRequest(true));
    return axiosAPI.bonuses.getBonusesValue(dataJson)
      .then( ({data: response}) => {
        if (response.status) {
          dispatch(getBonusesValueSuccess(response.data));
        }
      })
      .catch(error => {
        throw error;
      });
  };
}
