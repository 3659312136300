// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const ErrorWrapperStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: '100%'
  },
  errorText: {
    fontSize: 12,
    color: '#fd3321',
    paddingLeft: 20,
    marginTop: 2,
  },
}));
