// Core
import React, { FC } from 'react';
import { FieldMetaState } from 'react-final-form';
import clsx from 'clsx';
// UI
import FormControl from '@mui/material/FormControl';
// Style
import { ErrorWrapperStyles } from "./style";

interface InputProps {
  fullWidth?: boolean
  meta: FieldMetaState<string | number>
}

const ErrorWrapper: FC<InputProps> = (props) => {
  const {
    meta,
    fullWidth,
    children,
  } = props;
  const classes = ErrorWrapperStyles();
  const showErrors = meta?.error && (meta?.touched || meta?.submitSucceeded);
  const formControlClasses = clsx({
    [classes.fullWidth]: fullWidth,
  });

  return (
    <FormControl className={formControlClasses}>
      {children}
      {showErrors && <span className={classes.errorText}>{meta.error}</span>}
    </FormControl>
  );
};

ErrorWrapper.defaultProps = {
  fullWidth: true
};

export default ErrorWrapper;
