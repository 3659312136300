// Core
import React, { FC, memo } from 'react';
import ReactSelect from 'react-select';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from "react-i18next";
// Parts
import { reactSelectStylesModal, reactSelectStylesSelect } from './styles';

export type ReactSelectType = FieldRenderProps<string> & {
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  noOptionsMessageText?: string;
  defaultValue?: string;
  type?: "modal" | "default";
};

const Select: FC<ReactSelectType> = props => {
  const {
    isDisabled = false,
    isLoading = false,
    isClearable = false,
    isSearchable = false,
    placeholder = '',
    options = [],
    noOptionsMessageText,
    defaultValue,
    menuIsOpen,
    autoFocus,
    isMulti,
    onBlur,
    onChange,
    onFocus,
    value,
    type,
  } = props;

  const { t } = useTranslation();
  const noOptionsMessage = () => noOptionsMessageText || t('reactSelect.no.options');
  return (
    <ReactSelect
      menuIsOpen={menuIsOpen}
      autoFocus={autoFocus}
      isMulti={isMulti}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value}
      noOptionsMessage={noOptionsMessage}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      defaultValue={defaultValue}
      options={options}
      styles={type === 'modal' ? reactSelectStylesModal : reactSelectStylesSelect}
    />
  );
};

export default memo(Select);
