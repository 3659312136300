import { PRODUCTS_ADDITIONAL_DATA } from "../actions/actionTypes";

const { SET } = PRODUCTS_ADDITIONAL_DATA;

const productsDataReducer = ( state = {}, action ) => {
    const actionType = action.type;
    const actionPayload = action.payload;

    switch (actionType) {
        case SET:
            return {
                ...state,
                ...actionPayload
            };
        default:
            return state;
    }
};

export default productsDataReducer;