import { MARKDOWN } from "../actions/actionTypes";
const initialState = {};

const markdownReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case MARKDOWN.SET:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default markdownReducer;