import * as actionTypes from "../actions/actionTypes";

const initialState = null;

const initialsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CHECKOUT_INITIALS_SUCCESS:
        case actionTypes.GET_VALIDATION_TEXTS_SUCCESS:
        case actionTypes.SET_DEVICE_TYPE:
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export default initialsReducer;