// Parts
import { createAction } from '@reduxjs/toolkit';

export type postOrderStatusAsyncTypes = {
  'Order[orderNumber]': string;
};

export type postCanceledUserOrderAsyncTypes = {
  'order_id': string;
};

export type postOrderStatusLeadsCreateTypes = {
  email: string;
  order_id: string;
}

export type postOrderStatusQuestionFormAsyncTypes = {
  'mailParams[order_id]': string;
  'mailParams[comment]': string;
  'mailParams[email]': string;
  'mailParams[name]': string;
  'YII_CSRF_TOKEN': string;
};

const orderStatusAsyncAction = Object.freeze({
  postOrderStatusAsync: createAction<postOrderStatusAsyncTypes>('POST_ORDER_STATUS_ASYNC'),
  postOrderStatusLeadsCreateAsync: createAction<postOrderStatusLeadsCreateTypes>('POST_ORDER_STATUS_LEADS_CREATE_ASYNC'),
  postOrderStatusQuestionFormAsync: createAction<postOrderStatusQuestionFormAsyncTypes>('POST_ORDER_STATUS_QUESTION_FORM_ASYNC'),
  postCanceledUserOrderAsync: createAction<postCanceledUserOrderAsyncTypes>('POST_CANCELED_USER_ORDER_ASYNC'),
});

export const {
  postOrderStatusAsync,
  postOrderStatusLeadsCreateAsync,
  postOrderStatusQuestionFormAsync,
  postCanceledUserOrderAsync
} = orderStatusAsyncAction;
