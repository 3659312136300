// Core
import { createSlice } from '@reduxjs/toolkit';

interface RepairStatusState {
  loading?: boolean
  data?: {
    city?: string
    deliveryText?: string
    description?: string
    history?: {
      date: string
      description: string
      title: string
    }[]
    receiptNumber?: string
    statusColorType?: string
    statusText?: string
    texts?: {
      city?: string
      title?: string
      infoTitle?: string
      place?: string
      userInfo?: string
    }
  }
  error?: unknown,
  notify?: unknown,
  status?: unknown,
  showIdPlaceModal: boolean,
  clearValue: unknown,
}

const initialState: RepairStatusState = {
  loading: false,
  data: undefined,
  error: undefined,
  notify: undefined,
  status: undefined,
  showIdPlaceModal: false,
  clearValue: undefined,
}

const repairStatusSlice = createSlice({
  name: 'repairStatus',
  initialState,
  reducers: {
    setDefault(state) {
      Object.keys(initialState).map((objectKey) => {
        // @ts-ignore
        state[objectKey] = initialState[objectKey];
        return undefined;
      });
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setNotify(state, action) {
      state.notify = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setShowIdPlaceModal(state, action) {
      state.showIdPlaceModal = action.payload;
    },
    clearValue(state, action) {
      state.clearValue = action.payload;
    },
  },
});

export const {
  setDefault,
  setLoading,
  setData,
  setError,
  setNotify,
  setStatus,
  setShowIdPlaceModal,
  clearValue,
} = repairStatusSlice.actions;
export default repairStatusSlice.reducer;
