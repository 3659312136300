import { ADD_CART_PRODUCTS, SET_CART_PRODUCTS_COUNT, PRODUCT_WAS_REMOVED } from './events'

export function setCartProductsCount(productCount) {
    if (window.app && window.app.callEvent) {
        window.app.callEvent(SET_CART_PRODUCTS_COUNT, productCount);
    }
}

export function addCartProducts(cartData) {
    if (window.app && window.app.callEvent) {
        window.app.callEvent(ADD_CART_PRODUCTS, cartData);
    }
}

export function productWasRemoved({ items = [] }) {
    const { productId = null } = items[0] || {};

    if (window.app && window.app.callEvent && productId) {
        window.app.callEvent(PRODUCT_WAS_REMOVED, productId);
    }
}
