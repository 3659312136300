// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { $textColors } from "../../../../../engine/constants/theme";

const useStyles = makeStyles((theme: Theme) => ({
  question: {
    display: 'flex',
    margin: '0 0 26px 0',
    padding: 0,
    justifyContent: 'space-between',
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  questionLabel: {
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      paddingLeft: 12,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
    [theme.breakpoints.up('lg')]: {
      cursor: 'pointer',
    },
  },
  questionLabelWithImg: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(50% - 10px) !important',
    },
  },
  questionLabelDisabled: {
    pointerEvents: 'none',
  },
  questionImg: {
    backgroundColor: '#F7F8F9',
    borderRadius: 6,
    marginTop: 12,
    textAlign: 'center',
    '@global img': {
      display: 'block',
      width: '100%',
      maxWidth: 230,
      height: 'auto',
      margin: 'auto',
    },
  },
  questionText: {
    color: $textColors.$secondaryText,
    fontSize: 16,
    fontWeight: 400,
    margin: 0,
  },
  questionAnswerWrapper: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gridGap: 20,
    alignItems: 'stretch',
    [theme.breakpoints.down('sm')]: {
      gridGap: 0,
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  questionAnswerWrapperBool: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('lg')]: {
      marginTop: 12,
    },
  },
}));

export default useStyles;
