// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';

// Parts
import { postTypesAsync, postBrandsAsync, postModelsAsync, postQuestionsAsync, postGradeAsync, postLaptopItemsAsync } from './asyncAction';

// Workers
import {
  callPostTypesWorker,
  callPostBrandsWorker,
  callPostModelsWorker,
  callPostQuestionsWorker,
  callPostGradeWorker,
  callPostLaptopItemsWorker
} from './workers';

function* watchPostTypes() {
  yield takeLatest(postTypesAsync.type, callPostTypesWorker);
}
function* watchPostBrands() {
  yield takeLatest(postBrandsAsync.type, callPostBrandsWorker);
}
function* watchPostModels() {
  yield takeLatest(postModelsAsync.type, callPostModelsWorker);
}
function* watchPostQuestions() {
  yield takeLatest(postQuestionsAsync.type, callPostQuestionsWorker);
}
function* watchPostGrade() {
  yield takeLatest(postGradeAsync.type, callPostGradeWorker);
}
function* watchPostLaptopItems() {
  yield takeLatest(postLaptopItemsAsync.type, callPostLaptopItemsWorker);
}

export function* watchersTradeIn(): SagaIterator {
  yield all([
    call(watchPostTypes),
    call(watchPostBrands),
    call(watchPostModels),
    call(watchPostQuestions),
    call(watchPostGrade),
    call(watchPostLaptopItems),
  ]);
}
