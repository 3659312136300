// Core
import React, { FC, useEffect, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
// Global file and config
import { request } from '../../../../new-moyo/src/js/native/helpers/fetch.js';
import { EVENTS } from '../../../../new-moyo/src/js/native/constants/events.js';
import Base64 from '../../../../new-moyo/src/js/native/helpers/base64.js';
import { eventsObserverType } from '../containers/App';

type ProductDeliveryComponentType = {
  eventsObserver: eventsObserverType
  mobile: boolean
  productId: string
  cityId: string
  city: string
  async: string
  isPreorder: boolean
  isInArchive: boolean
  isInStock: boolean
  productType: string
}

type deliveryTextsType = { titleText: string }

type deliveryMethodsType = {
  deliveryPrice?: string
  deliveryColor: string | null
  bestTime?: string
  deliveryTime?: string
  alias?: string
  title?: string
}[];

const ProductDeliveryComponent:FC<ProductDeliveryComponentType> = (props) => {
  const {
    productId,
    city,
    cityId,
    async,
    eventsObserver,
    mobile,
    isPreorder,
    isInArchive,
    isInStock,
    productType
  } = props;
  const [isReady, setIsReady] = useState(false);
  const [currentCityData, setCurrentCityData] = useState({ city, cityId });
  const [deliveryMethods, setDeliveryMethods] = useState<deliveryMethodsType>([]);
  const [deliveryTexts, setDeliveryTexts] = useState<deliveryTextsType>({ titleText: '' });
  const [methodsText, setMethodsText] = useState('');
  const isAvailableInShop = productType === '1' && isInStock && !isPreorder && !isInArchive;

  const loadDeliveryData = useCallback((callCityId: string) => {
    const url = Base64.decodeOrReturnOriginal(async);
    if (!url || !callCityId) {
      return;
    }
    request(url, 'GET', undefined, { productId, cityId: callCityId })
      .then((res: { status: never; data: {methods: deliveryMethodsType, texts: deliveryTextsType, is_prepay: boolean, methodsMessage: string}; }) => {
        const { status, data } = res;
        if (status && data) {
          const methods = data.methods || [];
          const methodsMessage = data.methodsMessage || '';
          const texts = data.texts || {};
          setDeliveryMethods(methods);
          setMethodsText(methodsMessage);
          setDeliveryTexts(texts);
          setIsReady(!!methods.length || !!methodsMessage);
          window.app.callEvent(EVENTS.DELIVERY_DATA_PRODUCT_LOADED);
        }
      });
  }, [productId, async]);

  useEffect(() => {
    loadDeliveryData(cityId);

    // update city data listener
    let isFirstIteration = true;
    eventsObserver.subscribe(EVENTS.GET_HEADER_INFO_LOADED, (data) => {
      if (!isFirstIteration) {
        loadDeliveryData(data.cityId);
      } else {
        isFirstIteration = false;
      }
      setCurrentCityData({ city: data.city, cityId: data.cityId });
    });
  }, [cityId, eventsObserver, loadDeliveryData]);

  // clone delivery data for displaying in product_item (scrolled block) in product content
  useEffect(() => {
    const productItemDelivery = document.querySelector('.product_item_delivery');
    const deliveryData = document.getElementById('react_container__portal--delivery')?.cloneNode(true) || '';

    if (productItemDelivery?.hasChildNodes()) {
      productItemDelivery.innerHTML = '';
    }

    productItemDelivery?.append(deliveryData)

  }, [isReady, currentCityData.cityId, deliveryMethods])

  if (!isReady) {
    return null;
  }
  return (
    <>
      {mobile
        ? (
          <div className="product_delivery_city js-toggle-title">
            <div className="product_delivery_title">{deliveryTexts.titleText}</div>
          </div>
        )
        : (
          <div className="product_delivery_city js-show-city-modal">
            {deliveryTexts.titleText}: <span className="city">{currentCityData.city}</span>
          </div>
        )
      }
      <div className="product_delivery_type-list js-toggle-content">
        {mobile && (<span className="city js-show-city-modal">{currentCityData.city}</span>)}
        {deliveryMethods.map((deliveryData) => {
          const { deliveryColor, deliveryPrice = '' } = deliveryData;
          let { deliveryTime } = deliveryData;
          const isMoyoShop = deliveryData.alias === 'toShop';
          const isNp = deliveryData.alias === 'novaPoshta';
          const isNpBox = deliveryData.alias === 'novaPoshtaParcelMachine';
          const isCourier = deliveryData.alias === 'courier';
          let showTitleWithoutLink = false;

          if (isMoyoShop && !isAvailableInShop) {
            showTitleWithoutLink = true;
          } else if (!(isMoyoShop || isNp || isNpBox || isCourier) || isPreorder) {
            showTitleWithoutLink = true;
          }

          if (deliveryData.bestTime) {
            deliveryTime = deliveryData.bestTime;
          }

          return (
            <div className="product_delivery_type" key={deliveryData.alias}>
              <div className="product_delivery_type_description">
                <div className="product_delivery_type_name">
                  {(isMoyoShop && isAvailableInShop) &&
                    <span
                      className="product_delivery_type_name_link js-scroll-to"
                      data-href="#product-shop-list">{deliveryData.title}
                    </span>}
                  {(isNp && !isPreorder) &&
                    <span
                      className="product_delivery_type_name_link js-delivery-type-np">
                      {deliveryData.title}
                    </span>}
                  {(isNpBox && !isPreorder) &&
                    <span
                      className="product_delivery_type_name_link js-delivery-type-np-box">
                      {deliveryData.title}
                    </span>}
                  {(isCourier && !isPreorder) &&
                    <span className="product_delivery_type_name_link js-delivery-type-courier">
                      {deliveryData.title}
                    </span>}
                  {showTitleWithoutLink && deliveryData.title}
                </div>
                <div className="product_delivery_type_time">{deliveryTime}</div>
              </div>
              <div
                className="product_delivery_type_price"
                style={{ color: deliveryColor || undefined }}
                dangerouslySetInnerHTML={{ __html: deliveryPrice }}
              />
            </div>
          )
        })}
      </div>
      {!!methodsText && (
        <div className="product_delivery_type_additional">
          <div className="product_delivery_type_additional_icon" />
          <p className="product_delivery_type_additional_text">{methodsText}</p>
        </div>
      )}
    </>
  );
};

const ProductDeliveryPortal:FC<{ eventsObserver: eventsObserverType }> = (props) => {
  const { eventsObserver } = props;
  const Root = document.getElementById('react_container__portal--delivery');
  const productId = Root?.dataset?.productId || '';
  const dataAsync = Root?.dataset?.async || '';
  const cityId = Root?.dataset?.cityId || '';
  const city = Root?.dataset?.city || '';
  const isPreorder = Boolean(Root?.dataset?.preorder);
  const mobile = Boolean(Root?.dataset?.mobile);
  const isInArchive = Boolean(Root?.dataset?.isInArchive);
  const isInStock = Boolean(Root?.dataset?.isInStock);
  const productType = Root?.dataset?.productType || '';
  return Root ? createPortal(
    <ProductDeliveryComponent
      productId={productId}
      cityId={cityId}
      city={city}
      mobile={mobile}
      async={dataAsync}
      eventsObserver={eventsObserver}
      isPreorder={isPreorder}
      isInArchive={isInArchive}
      isInStock={isInStock}
      productType={productType}
    />
    , Root) : null;
};

export default ProductDeliveryPortal;
