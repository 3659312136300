import React from "react";
import { Provider } from "react-redux";
import { store } from "./reduxStore";
import App from "./root-app";

const CheckoutApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}
export default CheckoutApp;
