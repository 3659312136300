// Core
import React, { FC } from 'react';
import clsx from 'clsx';
import { FieldRenderProps } from 'react-final-form';
import InputAdornment from '@mui/material/InputAdornment';
// Parts
import TextField from '@mui/material/TextField';
import ErrorWrapper from '../ErrorWrapper';
import { inputStyles } from './styles';

export type InputTypes = FieldRenderProps<string> & {
  label?: string;
  startAdornment?: string | React.ReactNode;
  number?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  maxLength?: number;
  rows?: number;
  onChange?: () => never;
};

const Input: FC<InputTypes> = props => {
  const { label, fullWidth, meta, input, number, onChange, multiline, rows, startAdornment, maxLength } = props;
  const classes = inputStyles();
  const showErrors = meta?.error && (meta?.touched || meta?.submitSucceeded);
  const rootClasses = clsx(classes.root, {
    [classes.rootAdornmentLabel]: startAdornment,
    [classes.rootMultiline]: multiline,
    [classes.rootError]: showErrors,
  })
  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (number) {
      input.onChange(event.target.value.replace(/\D/g, ""))
    } else {
      input.onChange(event.target.value)
    }
    if (onChange) {
      onChange()
    }
  }

  return <ErrorWrapper meta={meta} fullWidth={fullWidth}>
    <TextField
      classes={{
        root: rootClasses,
      }}
      fullWidth={fullWidth}
      label={label}
      variant="filled"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      onChange={onChangeInput}
      multiline={multiline}
      rows={rows}
      inputProps={{
        maxLength,
      }}
      InputProps={{
        startAdornment: startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : undefined,
      }}
    />
  </ErrorWrapper>
};


export default Input;
