import { STORAGE_EVENT } from '../constants/events';

// STORAGE TYPE
// Can change to sessionStorage or localStorage
export const DEFAULT_STORAGE = sessionStorage;

export const STORAGE_KEYS = {
  SERVER_DATA: 'serverData',
  CHECKED_SERVICES: 'checkedServices',
  PREORDER_PRODUCTS: 'preorderProducts',
  GA_PRODUCTS_LIST: 'gaProductsList',
  EMERGENCY_NOTIFICATIONS: 'emergencyNotifications',
  ADVERTISING_MESSAGE: 'advertisingMessage',
  PRODUCTS_IN_CART: 'productsInCart',
};

const { SERVER_DATA, PRODUCTS_IN_CART } = STORAGE_KEYS;

// create custom event when storage is changed
const eventCreator = (data) => {
  return new CustomEvent(STORAGE_EVENT.ON_CHANGE_STORE, { detail: data });
};

// subscribe event example
// document.addEventListener(STORAGE_EVENT.ON_CHANGE_STORE, function(e) { process(e.detail) });

export const getServerData = () => {
  const storageData = DEFAULT_STORAGE.getItem(SERVER_DATA);
  return JSON.parse(storageData) || {};
};

export const getServerDataValue = (key) => {
  const serverData = getServerData();
  return serverData[key] || {};
};

export const getData = (key, storage = DEFAULT_STORAGE) => {
  const storageData = storage.getItem(key) || '';
  return storageData ? JSON.parse(storageData) : null;
};

export const setServerData = (data = {}, key) => {
  const serverData = getServerData();
  const newData = key
    ? { ...serverData, [key]: data }
    : { ...serverData, ...data };
  setData(SERVER_DATA, newData);
};

export const setData = (key, value, storage = DEFAULT_STORAGE) => {
  const newValue = JSON.stringify(value);
  storage.setItem(key, newValue);
  const event = eventCreator({ [key]: value });
  document.dispatchEvent(event);
};

export const clearSeverData = () => {
  DEFAULT_STORAGE.removeItem(SERVER_DATA);
};

export const clearData = (key, storage = DEFAULT_STORAGE) => {
  storage.removeItem(key);
};

const isStorageAvailable = (storageType = DEFAULT_STORAGE) => {
  const test = 'test';
  try {
    storageType.setItem(test, test);
    storageType.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
}

export function getCartProducts(storageType = DEFAULT_STORAGE) {
  return isStorageAvailable(storageType) && JSON.parse(storageType.getItem(PRODUCTS_IN_CART)) || [];
}

export function setCartProducts(storageType = DEFAULT_STORAGE, products = []) {
  isStorageAvailable(storageType) && localStorage.setItem('productsInCart', JSON.stringify(products))
}

function removeCartProduct(productId = null, storageType = DEFAULT_STORAGE) {
  if (isStorageAvailable && productId) {
    const updatedProducts = getCartProducts(storageType).filter(item => (item && item.productId) !== productId);

    storageType.setItem(PRODUCTS_IN_CART, JSON.stringify(updatedProducts));
  }
}
