import {combineEpics} from "redux-observable";
import {getDataGraphMiddleware, getDataGraphMiddlewareCredit, creditPeriodChangeGraph} from "./dataGraphMiddleware"
import {axiosAPI} from "../../../../engine/config/axios";

export const rootEpic = combineEpics(
  getDataGraphMiddleware,
  getDataGraphMiddlewareCredit,
  creditPeriodChangeGraph
);

export const epicMiddlewareOptions = {
  dependencies: {
    fetchGraph: axiosAPI.graphApi.graphObservable
  }
}
