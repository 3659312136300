import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom/";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from "lodash/debounce";
import { setDeviceType } from "./actions/initialsActions";
import { isMobile } from "../../functions/isMobile";
import "../../assets/styles/index.scss";

const App = ({
               setDeviceType,
             }) => {
  const children = document.querySelectorAll("[id^='react-child']");
  const detectDevice = debounce(() => {
    setDeviceType({
      isMobile: isMobile(window.navigator),
    });
  }, 150);

  useEffect(() => {
    setDeviceType({
      isMobile: isMobile(window.navigator),
    });

    window.addEventListener("resize", detectDevice, false);

    return () => {
      window.removeEventListener("resize", detectDevice, false);
    };
  }, []);

  if (!children.length) {
    return null;
  }

  return (
      <>
        {[].map.call(children, child => {
          const childAppName = child.id.replace(/^react-child-/, "");
          const ChildApp = lazy(() => import(`./${childAppName}.js`));
          return (
              ReactDOM.createPortal(
                  <Suspense fallback={""}>
                    <ChildApp />
                  </Suspense>,
                  child
              )
          );
        })}
      </>
  );
};

const mapDispatchToProps = dispatch => ({
  setDeviceType: bindActionCreators(setDeviceType, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(App);
