// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { $textColors } from "../../../../../engine/constants/theme";

const useStyles = makeStyles((theme: Theme) => ({
  propose: {
    color: $textColors.$dark,
    position: 'sticky',
    top: 70,
    overflow: 'hidden',
    width: '50%',
    borderRadius: 8,
    marginTop: 8,
    background: '#fff',
    padding: 32,
    [theme.breakpoints.down('md')]: {
      width: 'initial',
    },
  },
  proposePrice: {
    lineHeight: 1,
    display: 'block',
    fontWeight: 'bold',
    fontSize: 40,
    marginBottom: 24,
  },
  proposeTitle: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1.1,
    marginBottom: 32,
    marginTop: 0,
  },
  proposeSubtitle: {
    margin: 0,
    fontSize: '16px',
    color: $textColors.$secondaryText,
  },
  proposeSvg: {
    display: 'block !important',
    marginBottom: '8px !important',
    fontSize: '10px !important',
  },
  proposeInfo: {
    margin: '16px 0',
  },
}));

export default useStyles;
