// Core
import React, { FC } from 'react';
// Parts
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './styles';
import { $textColors } from "../../../engine/constants/theme";

type BootstrapDialogTitleType = {
  onClose: () => void
  children?:
    | JSX.Element
    | JSX.Element[]
    | string
    | string[];
}

const BootstrapDialogTitle:FC<BootstrapDialogTitleType> = (props) => {
  const { children, onClose } = props;
  const classes = useStyles();

  return (
    <DialogTitle sx={{ m: 0, p: 0 }}>
      <div className={classes.title}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 20,
              color: $textColors.$dark,
              transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              '&:hover': {
                transform: 'rotate(90deg)'
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
};

BootstrapDialogTitle.defaultProps = {
  children: undefined
}

type ModalType = {
  open: boolean
  onClose: () => void
  title?: string
  children:
    | JSX.Element
    | JSX.Element[]
    | string
    | string[]
}

const Modal:FC<ModalType> = (props) => {
  const { children, title, open, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="lg"
      classes={{
        paper: classes.root
      }}
      onClose={onClose}
    >
      <DialogContent
        classes={{
          root: classes.dialogContent
        }}
        className="custom-scroll"
      >
        <BootstrapDialogTitle onClose={onClose}>
          {title}
        </BootstrapDialogTitle>
        {children}
      </DialogContent>
    </Dialog>
  );
}

Modal.defaultProps = {
  title: undefined
}

export default Modal;
