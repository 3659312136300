import {ofType} from "redux-observable";
import {of, merge} from "rxjs";
import {map, catchError, concatAll, takeUntil, switchMap, concatWith} from "rxjs/operators";
import {GRAPH, SET_CART_CREDIT_ERROR} from "../actions/actionTypes";
import { headersType } from "../../../../engine/config/axios";
import {
    getDataGraphSuccess,
    getDataGraphConcatAll,
} from "../actions/cartActions";
import get from "lodash/get";
import {toggleBonusesLoader} from "../actions/bonusesActions";
import {creditGraphSuccess, setCartCreditError, setWaitGraph} from "../actions/creditActions";
import {getDeliveryMethodsSuccess} from "../actions/deliveryActions";

const BASEURL = process.env.REACT_APP_API_DOMAIN;

export const getDataGraphMiddleware = (action$, state, {fetchGraph}) => action$.pipe(
    ofType(GRAPH.CREDIT_GRAPH_GET_ALL),
    map(({payload: [payload, checkBonuses]}) => fetchGraph(payload)
        .pipe(
            map(({response}) => {
                const stateBonuses = get(state, "value.cart.useBonuses", null);
                const responseBonuses = get(response, "data.models.cart.data.useBonuses", null);

                // якщо вибрали тип оплати НЕ кредитний і попередній graph з інишою к-сть бонусів але ще не змінив state;
                // повторюємо той самий запит з оновленими бонусами з попереднього graph;
                if (checkBonuses && stateBonuses !== responseBonuses) {
                    const [cart] = payload.models.filter(item => item.name === "cart");
                    const restModels = payload.models.filter(item => item.name !== "cart");
                    cart.params.bonuses = stateBonuses;
                    const newParams = {
                        models: [
                            ...restModels,
                            cart
                        ],
                    };

                    return getDataGraphConcatAll(newParams, false)
                }
                return getDataGraphSuccess(response.data.models);
            }),
            takeUntil(action$.pipe(
                ofType(GRAPH.CREDIT_GRAPH_GET_CANCEL_PREV),
                concatWith(of(toggleBonusesLoader(false)))
            )),
            catchError(error => of(setCartCreditError(error))),
            concatWith(of(toggleBonusesLoader(false))),
        ),
    ),
    concatAll()
)

export const getDataGraphMiddlewareCredit = (action$, _, {fetchGraph}) => action$.pipe(
    ofType(GRAPH.CREDIT_GRAPH_GET_CANCEL_PREV),
    switchMap(({payload}) => fetchGraph(payload).pipe(
            map(({response}) => getDataGraphSuccess(response.data.models)),
            catchError(error => of(setCartCreditError(error)))
        )
    )
)

export const creditPeriodChangeGraph = (action$, _, {fetchGraph}) => action$.pipe(
    ofType(GRAPH.CREDIT_PERIOD_GRAPH_REQUEST),
    switchMap(({payload}) => merge(
            of(setWaitGraph(true)),
            fetchGraph(payload).pipe(
                switchMap(({response}) => {
                    const newData = get(response, "data.models", {});
                    const paymentStatus = get(newData, "paymentMethods.status", false);
                    const paymentData = get(newData, "paymentMethods.data.methods", []);
                    const currentPaymentCredit = paymentData.find(i => i.alias === "paymentCredit");
                    const data = currentPaymentCredit && currentPaymentCredit.data ? currentPaymentCredit.data : {};
                    const deliveryMethods = get(newData, "deliveryMethods.data", null);
                    const arrayOfActions = [getDataGraphSuccess(newData)];
                    paymentStatus && arrayOfActions.push(creditGraphSuccess(data));
                    deliveryMethods && arrayOfActions.push(getDeliveryMethodsSuccess(deliveryMethods));
                    arrayOfActions.push(setWaitGraph(false));

                    return of(...arrayOfActions);
                }),
                catchError(error => of(
                        setCartCreditError(error),
                        setWaitGraph(false)
                    )
                ),
            ),
        )
    )
);

