// Core
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
// Style
import './style.scss';

type questionFormType = {
    texts?: {
        title?: string
        text?: string
        btnText?: string
        messageInputPlaceholder?: string
        nameInputPlaceholder?: string
        emailInputPlaceholder?: string
    }
    formSuccessText?: string
}

const QuestionForm: FC<questionFormType> = (props) => {
    const {
        texts,
        formSuccessText,
    } = props;
    const { t } = useTranslation();
    const normalizeTitle = texts?.title || t('questionForm.title');
    const normalizeText = texts?.text || t('questionForm.text');

    return (
        <div className="question-form">
            <div className="question-form_container">
                <div className="question-form_content">
                    {formSuccessText
                        ? <div className="question-form_success-text">{formSuccessText}</div>
                        : <>
                            <div className="question-form_title">
                                {normalizeTitle}
                            </div>
                            <div className="question-form_text">
                                {normalizeText}
                            </div>
                            <div className="question-form_social-block">
                                <a
                                  href="https://t.me/MOYObot_bot"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn--tg"
                                >
                                    Telegram
                                    <span />
                                </a>
                                <a
                                  href="viber://pa?chatURI=moyoua"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn btn--vb"
                                >
                                    Viber
                                    <span />
                                </a>
                            </div>
                        </>
                    }
                </div>
                <div className="question-form_img"/>
            </div>
        </div>
    );
};

QuestionForm.defaultProps = {
    texts: {
        title: undefined,
        text: undefined,
        btnText: undefined,
        messageInputPlaceholder: undefined,
        nameInputPlaceholder: undefined,
        emailInputPlaceholder: undefined,
    },
    formSuccessText: undefined,
};

export default QuestionForm;
