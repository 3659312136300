// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';

// Parts
import { postOrderStatusAsync, postOrderStatusQuestionFormAsync, postOrderStatusLeadsCreateAsync, postCanceledUserOrderAsync } from './asyncAction';

// Workers
import {
  callPostOrderStatusWorker,
  callPostOrderStatusQuestionsFormWorker,
  callPostOrderStatusLeadsCreateWorker,
  callPostCanceledUserOrderWorker
} from './workers';

function* watchPostOrderStatus() {
  yield takeLatest(postOrderStatusAsync.type, callPostOrderStatusWorker);
}
function* watchPostOrderStatusLeadsCreate() {
  yield takeLatest(postOrderStatusLeadsCreateAsync.type, callPostOrderStatusLeadsCreateWorker);
}
function* watchPostOrderStatusQuestionForm() {
  yield takeLatest(postOrderStatusQuestionFormAsync.type, callPostOrderStatusQuestionsFormWorker);
}
function* watchPostCanceledUserOrder() {
  yield takeLatest(postCanceledUserOrderAsync.type, callPostCanceledUserOrderWorker);
}

export function* watchersOrderStatus(): SagaIterator {
  yield all([
    call(watchPostOrderStatus),
    call(watchPostOrderStatusLeadsCreate),
    call(watchPostOrderStatusQuestionForm),
    call(watchPostCanceledUserOrder),
  ]);
}
