export const CALCULATION_TYPES = {
    PERCENTAGE: 2,
    FIXED: 1
};

export const CART_TYPES = {
    SIMPLE: "simply",
    CREDIT: "credit",
    EMPTY: "empty",
    TARGET: "target",
    CREDIT_WITH_UNAVAILABLE_PRODUCTS: "CREDIT_WITH_UNAVAILABLE_PRODUCTS",
    PREORDER: 'preorder',
};

export const CREDIT_SUB_TYPES = {
    FREE_TYPE: "freeType",
    PAID_TYPE: "paidType",
};

export const PROMOCODES = {
    LIMIT: 3,
    REDUX_FORM_NAME: "checkout-cart-promocode-form",
};

export const COOKIES_DATA_GROUP = {
    CHECKOUT_CONTACTS_STEP: "checkoutContactsStepData",
    CHECKOUT_DELIVERY_STEP: "checkoutDeliveryStepData",
    CHECKOUT_PAYMENT_STEP: "CHECKOUT_PAYMENT_STEP"
};

export const ORDER = {
    LOADER_TIMEOUT: 30000,
    LIMIT_START_PACKAGES: 3,
    START_PACKAGES_CATEGORY_ID: 2862,
    LIMIT_PS5: 1,
    PS5_PRODUCT_ID: [468817, 468487],
};

export const AUTHORIZATION = {
    RE_CAPTCHA_ID: "registration-recaptcha",
};

export const SERVICE = {
    modalId: "SERVICE",
}

export const CART = {
    modalId: "CART",
    modalTargetCreditId: "TARGET_CREDIT",
}

export const DELIVERY_METHOD = {
    TO_SHOP_ALIAS: "toShop",
    NOVA_POSHTA_ALIAS: "novaPoshta",
    NOVA_POSHTA_PARCEL_MACHINE_ALIAS: "novaPoshtaParcelMachine",
    JUST_IN_ALIAS: "justIN",
    COURIER_ALIAS: "courier",
    NOVA_POSHTA_DAY_IN_DAY_ALIAS: "novaPoshtaDayInDay",
    ONLINE_ALIAS: "online",
    AIR_BRANCH: "airBranch",
    AIR_ADDRESS: "airAddress",
    FLOOR_LIMIT: 5,
};

export const COMMISSION_CREDIT = {
    FREE_TYPE: 0.01
};

export const DELIVERY_FORMS_NAME = {
    COURIER : 'delivery-courier-form',
    COURIER_USER: 'delivery-courier-user-additional-form',
    AIR_ADDRESS: `delivery-${DELIVERY_METHOD.AIR_ADDRESS}-form`,
    AIR_ADDRESS_USER: `delivery-${DELIVERY_METHOD.AIR_ADDRESS}-user-additional-form`,
}

export const FIRST_PAYMENT = {
    VALUE: "FIRST_PAYMENT_VALUE",
}

export const PAYMENT_METHODS = {
    VISA_MASTER_CARD : 'paymentEcommerce',
    PRIVAT_PAY : 'paymentPrivatPay',
}

export const HOTLINE = {
    CHECKBOX_ID : 'orderForHotline',
    FORM_NAME: "checkout-hotline-form"
}

export const CHECKOUT_CONTACTS_FORM_NAME =  'checkout-contacts-form';

export const BONUSES_PAYMENT_STEPS = {
    VERIVICATION: "BONUSES_VERIVICATION_STEP",
    NUMBER_CONFIRM: "BONUSES_NUMBER_CONFIRM_STEP",
    PAYMENT: "BONUSES_PAYMENT_STEP",
    EDRPOU_NOTIFICATION: "EDRPOU_NOTIFICATION",
};

export const PAYMENT_ALIAS_METHOD = {
    PAYMENT_CREDIT: "paymentCredit",
    PAYMENT_CASHLESS: "paymentCashless",
    PAYMENT_DISCOUNT: "paymentDiscount",
};

export const DIIA = {
    MODAL_STEPS_AUTH: "MODAL_STEPS_AUTH",
    MODAL_STEPS_QR: "MODAL_STEPS_QR",
    TIMER_TIME: 3 * 60 * 1000, // 3 минуты
    BUTTON_TIME: 10 * 1000,
    USER_CONSENT_LINK: '/useful/ugoda_pro_zbir_obrobku_ta_zberigannya_personalnikh_danikh_klientiv_tovaristva_z_obmezhenoyu_vidpovidalnistyu_nrp_ta_zgoda_na_peredachu_danikh_tretim_osobam.html',
    FORM_NAME_TARGET: `payment-ТАРГЕТФИНАНС-credit-form`,
    FORM_NAME_PAY_LATER: `payment-ЦОФ000942-credit-form`,
    ACTIVE_CREDIT_ARRAY: ['ЦОФ034884'],
};

export const BINANCE = {
    REDUX_FORM_NAME: "checkout-cart-binance-form",
};

export const DEFAULT_RUNNING_OUT_COUNT = 5;
export const CITY_ID_KYIV = '3390';
export const DEFAULT_PURCHASE_LIMIT = 0;
export const DEFAULT_PREORDER_LIMIT = 0;
export const DEFAULT_MAX_ONSALE = 0;

export const CART_LIMIT_TEXT_SELECTORS = {
    DEFECT_TEXT: 'defectText',
    RUNNING_OUR_TEXT: 'runningOutText',
    MAX_PURCHASE_TEXT: 'maxPurchaseText',
}
export const LIMIT_OF_ROWS_FOR_NP = 100;

export const DEFAULT_STORAGE = sessionStorage;

export const STORAGE_KEYS = {
    SERVER_DATA: 'serverData',
    CHECKED_SERVICES: 'checkedServices',
    PREORDER_PRODUCTS: 'preorderProducts',
    GA_PRODUCTS_LIST: 'gaProductsList',
    EMERGENCY_NOTIFICATIONS: 'emergencyNotifications',
    PRODUCTS_IN_CART: 'productsInCart',
};

export const PRICE_INTERNAL_FORM_NAME = 'price-internal-form';
