// возвращает куки с указанным name,
// или undefined, если ничего не найдено
export const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(
        `(?:^|; )${  name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')  }=([^;]*)`
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const setCookie = (name, value, options = {}) => {
    options = { // eslint-disable-line
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options.expires && options.expires.toUTCString) {
        options.expires = options.expires.toUTCString(); // eslint-disable-line
    }

    let updatedCookie = `${encodeURIComponent(name)  }=${  encodeURIComponent(value)}`;

    for (const optionKey in options) { // eslint-disable-line
        updatedCookie += `; ${  optionKey}`;
        const optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += `=${  optionValue}`;
        }
    }

    document.cookie = updatedCookie;
}

// Пример использования:
// setCookie('user', 'John', {secure: true, 'max-age': 3600});


export const deleteCookie = (name) => {
    setCookie(name, "", {
      'max-age': -1
    })
}
