import React, { FC, Suspense } from 'react';
import { Provider } from 'react-redux';
// styles
import { ThemeProvider } from '@mui/material/styles';
// Engine
import '../../engine/config/i18n';
import { store } from '../../engine/init/store';
// Global file and config
import Events from '../../../../new-moyo/src/js/native/components/Events.js';
// Parts
import TradeInPortal from '../portals/TradeIn';
import StatusPortal from '../portals/Status';
import ProductDeliveryPortal from '../portals/ProductDelivery';
import ProductServicesPortal from '../portals/ProductServices';
// Config
import style from '../assets/styles/global.module.scss';
import theme from '../../engine/config/theme';

export type eventsObserverType = {
  subscribe: (eventName: string, callBack: (data: { [key: string]: string; }) => void) => void
  unsubscribe: () => void
};

const eventsObserver = new Events();

const App: FC = () => (
  <div className={style.react_app}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<></>}>
          <TradeInPortal />
          <StatusPortal />
          <ProductServicesPortal />
          <ProductDeliveryPortal eventsObserver={eventsObserver} />
        </Suspense>
      </ThemeProvider>
    </Provider>
  </div>
);

export default App;
