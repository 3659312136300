// Core
import { makeStyles } from '@mui/styles';
// Styles
import "./style.scss";

const useStyles = makeStyles({
  wrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    maxWidth: 461,
    padding: 16
  },
});

export default useStyles;
