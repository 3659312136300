// Core
import { makeStyles } from '@mui/styles';
import { StylesConfig } from 'react-select';
import { $borderColors, $colors } from "../../../../engine/constants/theme";

export const selectStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    marginBottom: 8,
    marginTop: 8,
    cursor: 'pointer',
    '@global *': {
      cursor: 'pointer',
      '&::-webkit-scrollbar-track': { borderRadius: '10px' },
      '&::-webkit-scrollbar': { width: '6px', height: '6px' },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#d4d4d4'
      }
    },
  },
  border: {
    border: `1px solid ${$borderColors.$border}`,
  },
  arrow: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 20,
    top: 22,
    transform: 'rotate(45deg)',
    border: 'solid black',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: '3px',
    verticalAlign: 'middle',
  },
  disabledArrow: {
    opacity: '0.5'
  },
  inputRoot: {
    backgroundColor: '#fff',
    borderRadius: 8,
    overflow: 'hidden',
    '@global .Mui-disabled': {
      backgroundColor: '#fff',
      pointerEvents: 'none',
      '&:before': {
        content: 'none',
      },
    },
    '@global input': {
      paddingLeft: 20,
      paddingTop: 23,
      paddingRight: 12,
      paddingBottom: 6,
      color: '#292B31 !important',
      WebkitTextFillColor: 'inherit !important',
    },
    '@global .MuiInputLabel-root': {
      paddingLeft: 13,
      color: $colors.$muiLabelColor,
      backgroundColor: 'transparent',
    },
  },
  inputRootDisabled: {
    pointerEvents: 'none',
    '@global .MuiInputLabel-root': {
      pointerEvents: 'none',
    },
  }
}));

export const reactSelectStylesModal: StylesConfig<string, false> = {
  container: libStyles => ({
    ...libStyles,
    marginTop: 2,
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  singleValue: libStyles => ({
    ...libStyles,
    paddingTop: 1,
  }),
  control: () => ({
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    backgroundColor: 'hsl(0, 0%, 100%)',
    border: `1px solid ${$colors.$main}`,
    fontSize: 16,
    cursor: 'pointer',
    padding: '10px 20px',
  }),
  input: libStyles => ({
    ...libStyles,
    cursor: 'pointer',
  }),
  menu: () => ({
    margin: 0,
    backgroundColor: '#fff',
    border: `1px solid ${$borderColors.$border}`,
    borderTop: 'none',
    overflow: 'hidden',
    borderBottomLeftRadius: 28,
    borderBottomRightRadius: 28,
    position: 'absolute',
    zIndex: '99999',
    width: '100%',
  }),
  menuList: libStyles => ({
    ...libStyles,
    backgroundColor: '#fff',
    maxHeight: 300,
    overflowY: 'auto',
    marginBottom: 20,
    padding: 0,
  }),
  option: (libStyles, { isFocused, isSelected }) => ({
    cursor: 'pointer',
    padding: '16px 20px',
    fontSize: 16,
    fontFamily: 'Circe ,sans-serif',
    backgroundColor: isSelected || isFocused ? '#f7f8f9' : '#fff',
  }),
  valueContainer: libStyles => ({
    ...libStyles,
    padding: 0,
    margin: 0,
  }),
};

export const reactSelectStylesSelect: StylesConfig<string, false> = {
  container: libStyles => ({
    ...libStyles,
    marginTop: 2,
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  singleValue: libStyles => ({
    ...libStyles,
    paddingTop: 1,
  }),
  control: () => ({
    display: 'none',
  }),
  input: libStyles => ({
    ...libStyles,
    cursor: 'pointer',
  }),
  menu: () => ({
    margin: 0,
    backgroundColor: '#fff',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '99999',
    width: '100%',
    boxShadow: '0px 3px 5px 1px rgb(34 45 74 / 10%)',
    borderRadius: 8,
    marginTop: 4,
  }),
  menuList: libStyles => ({
    ...libStyles,
    backgroundColor: '#fff',
    maxHeight: 305,
    borderRadius: 8,
    overflowY: 'auto',
    marginBottom: 0,
    padding: 0,
  }),
  option: (libStyles, { isFocused, isSelected }) => ({
    cursor: 'pointer',
    padding: '16px 20px',
    fontSize: 16,
    fontFamily: 'Circe ,sans-serif',
    backgroundColor: isSelected || isFocused ? '#f7f8f9' : '#fff',
  }),
};
