import * as types from "./actionTypes";
import { TOP_PRICE, GRAPH, FIRST_PAYMENT } from "./actionTypes";
import { axiosAPI } from "../../../../engine/config/axios";

export function getCartCreditSuccess(data) {
    return {
        type: types.GET_CART_CREDIT_SUCCESS,
        payload: data,
    };
}

export function clearCartCredit() {
    return {
        type: types.CLEAR_CART_CREDIT,
    };
}

export function setCreditSubType(type) {
    return {
        type: types.SET_CREDIT_SUB_TYPE,
        payload: type,
    };
}

export function toggleCreditType(data) {
    return {
        type: types.TOGGLE_CREDIT_TYPE,
        payload: data,
    };
}

export function setCreditTypeData(data) {
    return {
        type: types.SET_CREDIT_TYPE_DATA,
        payload: data,
    };
}

export function setCartCreditError(data) {
    return {
        type: types.SET_CART_CREDIT_ERROR,
        payload: data,
    };
}

export function getCreditFormByTypeCodeSuccess(data) {
    return {
        type: types.GET_CREDIT_FORM_BY_TYPE_CODE_SUCCESS,
        payload: data,
    };
}

export function getCartCredit(dataJson) {
    return function(dispatch) {
        return axiosAPI.cart.getCartCredit(dataJson)
            .then(({data: credit}) => {
                dispatch(getCartCreditSuccess(credit.data));
                return credit.data;
            })
            .catch(error => {
                throw error;
            });
    };
}

export function getCreditFormByTypeCode({creditTypeCode, creditSubType, target = false}) {
    return function(dispatch) {
        return axiosAPI.payment.getCreditFormByTypeCode(target? {target} : {creditTypeCode})
            .then(({data: result}) => {
                if ( creditTypeCode !== "ТАРГЕТФИНАНС") {
                    dispatch(getCreditFormByTypeCodeSuccess({
                        creditTypeCode,
                        creditSubType,
                        form: result.data,
                    }));
                } else {
                    dispatch(getCreditFormByTypeCodeSuccess({
                        creditTypeCode,
                        creditSubType,
                        target,
                        form: result.data,
                    }));
                }

            })
            .catch(error => {
                throw error;
            });
    };
}


export function setCreditTargetData(payload) {
    return {
        type: types.SET_CREDIT_TARGET_DATA,
        payload,
    };
}

export function toggleCreditTargetType(payload) {
    return {
        type: types.TOGGLE_CREDIT_TARGET_TYPE,
        payload,
    };
}

export const setTopPriceData = payload => ({
    type: TOP_PRICE.SET_PRODUCTS_DATA,
    payload,
});

export const clearTopPriceData = () => ({
    type: TOP_PRICE.CLEAR_PRODUCTS_DATA,
});


export const setTotalOrderTopPrice = payload => ({
    type: TOP_PRICE.SET_TOTAL_ORDER_TOP_PRICE,
    payload,
});

export const clearTotalOrderTopPrice = () => ({
    type: TOP_PRICE.CLEAR_TOTAL_ORDER_TOP_PRICE,
});

export const toggleInfoTopPrice = payload => ({
    type: TOP_PRICE.TOGGLE_INFO,
    payload,
});

export const setCreditNumberMonths = payload => ({
    type: types.SET_CREDIT_NUMBER_MONTHS,
    payload,
});

export const setTotalOrderBonus = () => ({
    type: TOP_PRICE.SET_TOTAL_ORDER_BONUS,
});
export const clearTotalOrderBonus = () => ({
    type: TOP_PRICE.CLEAR_TOTAL_ORDER_BONUS,
});

export const creditGraphSuccess = payload => ({
    type: GRAPH.CREDIT_GRAPH_SUCCESS,
    payload,
});

export const getGraphCreditPeriod = payload => ({
    type: GRAPH.CREDIT_PERIOD_GRAPH_REQUEST,
    payload,
});

export function getGraphCreditData(dataJson) {
    return function(dispatch) {
        dispatch(setWaitGraph(true));
        return axiosAPI.graphApi.graph(dataJson)
          .then(({data: result}) => {
              if (result.status) {
                  const {
                      paymentMethods,
                  } = result.data && result.data.models || {};

                  if (paymentMethods && paymentMethods.status) {
                      const paymentData = paymentMethods.data && paymentMethods.data.methods || [];
                      const currentPaymentCredit = paymentData.find(i => i.alias === "paymentCredit");
                      const data = currentPaymentCredit && currentPaymentCredit.data ? currentPaymentCredit.data : {};
                      dispatch(setWaitGraph(false));
                      dispatch(creditGraphSuccess(data));
                  }
              }
          })
          .catch(error => {
              throw error;
          }).finally(() => {
              dispatch(setWaitGraph(false));
          });
    };
}

export const setFirstPayment = payload => ({
    type: FIRST_PAYMENT.SET,
    payload,
});

export const setWaitGraph = payload => {
    return {
        type: types.SET_WAIT_GRAPH,
        payload,
    }
};
