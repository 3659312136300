// Core
import { SagaIterator } from '@redux-saga/core';
import { all, call, takeLatest } from 'redux-saga/effects';

// Parts
import { postRepairStatusAsync } from './asyncAction';

// Workers
import {
  callPostRepairStatusWorker
} from './workers';

function* watchRepairOrderStatus() {
  yield takeLatest(postRepairStatusAsync.type, callPostRepairStatusWorker);
}

export function* watchersRepairStatus(): SagaIterator {
  yield all([
    call(watchRepairOrderStatus),
  ]);
}
