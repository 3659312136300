// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { $textColors } from "../../../../../engine/constants/theme";

const useStyles = makeStyles((theme: Theme) => ({
  questions: {
    padding: '18px 20px',
    borderRadius: 8,
    background: '#fff',
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      '& h6': {
        color: $textColors.$secondaryText,
        fontSize: 16,
        fontWeight: 400,
      },
      '& label': {
        width: '100%',
        paddingLeft: 0,
        justifyContent: 'space-between',
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
  questionsTitle: {
    fontSize: 18,
    marginTop: 0,
    marginBottom: 25,
  },
}));

export default useStyles;
