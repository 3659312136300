// Core
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
// Instruments
export const history = createBrowserHistory();
const sagaMiddleWare = createSagaMiddleware();
const reactRouterMiddleware = routerMiddleware(history);
const middleware = [
  sagaMiddleWare,
  reactRouterMiddleware,
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }),
];

export { sagaMiddleWare, middleware };
