import React, { FC } from 'react';

const OrderStatusEmptyContent:FC = () => {
  const orderStatusTexts = window.orderStatusTexts || {};
  return (
    <div className="order-status_info-text">
      <div className="order-status_info-text_content">
        {orderStatusTexts.orderEmptyData}
      </div>
    </div>
  )
};


export default OrderStatusEmptyContent;
