import * as types from "../actions/actionTypes";
import { TOP_PRICE } from '../actions/actionTypes';

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CART_SUCCESS:
            const newCart = action.payload;
            if  (state?.productsDates) {
                newCart.products = newCart.products?.map(product => ({
                    ...product,
                    deliveryTime: state.productsDates[product.productId] || product.deliveryTime
                }))
            }
            return {
                ...state,
                ...newCart,
            };

        case types.SET_RECOVERY_ITEMS:
            return {
                ...state,
                ...{
                    recoveryItems: [
                        ...(state.recoveryItems ? state.recoveryItems : []).slice(),
                        ...action.payload.items,
                    ],
                },
            };

        case types.CLEAR_RECOVERY_ITEMS:
            return {
                ...state,
                ...{
                    recoveryItems: [],
                },
            };

        case types.SET_CART_TYPE:
            return {
                ...state,
                ...{
                    cartType: action.payload,
                },
            };

        case TOP_PRICE.SET_PRODUCTS_DATA:
            const prices = action.payload.prices || null;
            const bonuses = action.payload.bonuses || null;

            let codeProducts = Object.keys(prices);

            const productsWithTopPrice = state.products.map( item => {
                if ( codeProducts.includes(item.code1c) && !!prices && item.price != prices[item.code1c] ) {
                    return {
                        ...item,
                        topPriceValue: prices[item.code1c],
                        bonusTopPrice: bonuses[item.code1c],
                    }
                }
                if (!!item.topPriceValue) {
                    return {
                        ...item,
                        topPriceValue: null
                    }
                }
                return item;
            });

            return {
                ...state,
                products: [...productsWithTopPrice],
            };

        case TOP_PRICE.CLEAR_PRODUCTS_DATA:
            return {
                ...state,
                products: state.products.map( item => ({
                    ...item,
                    topPriceValue: null,
                    bonusTopPrice: null,
                })),
            };

        case TOP_PRICE.SET_TOTAL_ORDER_TOP_PRICE:
            return {
                ...state,
                cartTotalOrderTopPrice: state.products.reduce( (prevValue, product) => {
                    if (product.topPriceValue) {
                        return prevValue + (product.topPriceValue * product.count);
                    }
                    return prevValue + (product.price * product.count);
                },0),
            };

        case TOP_PRICE.CLEAR_TOTAL_ORDER_TOP_PRICE:
            return {
                ...state,
                cartTotalOrderTopPrice: null,
            };
        case TOP_PRICE.TOGGLE_INFO:
            return {
                ...state,
                isDisplayTopPriceInfo: action.payload,
            };

        case TOP_PRICE.GET_DATA_GRAPH_SUCCESS:
            const cartData = action.payload.cart && action.payload.cart.data || {};

            if  (state?.productsDates) {
                cartData.products = cartData.products?.map(product => ({
                    ...product,
                    deliveryTime: state.productsDates[product.productId] || product.deliveryTime
                }))
            }
            return {
                ...state,
                ...cartData,
                texts: {
                    ...state.texts,
                    ...cartData.texts,
                },
            };

        case TOP_PRICE.SET_TOTAL_ORDER_BONUS:
            return {
                ...state,
                cartTotalTopPriceBonus: state.products.reduce( (prevValue, product) => {
                    const {bonusTopPrice, bonus, count} = product;
                    if (bonusTopPrice) {
                        return prevValue + (bonusTopPrice * count);
                    }
                    return prevValue + bonus;
                },0),
            };
        case TOP_PRICE.CLEAR_TOTAL_ORDER_BONUS:
            return {
                ...state,
                cartTotalTopPriceBonus: null,
                useBonuses: null,
            };

    case types.GET_CART_PRODUCTS_DATES_SUCCESS:
            if (!action.payload) {
                return state;
            }

            const dateMap = action.payload;
            const updatedProducts = state?.products?.map(product => {
                return {
                    ...product,
                    deliveryTime: dateMap[product.productId] || product.deliveryTime
                };
            });

            return {
                ...state,
                products: updatedProducts,
                productsDates: action.payload
            };

        default:
            return state;
    }
};

const initialState = null;

export default cartReducer;
