// Core
import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
  loading: true,
  data: [],
  error: undefined,
  notify: undefined,
  status: undefined,
};

export const gradeDefaultState = {
  ...defaultState,
  loading: false,
  data: {
    name: null,
    price: null,
    level: null,
    codeOutOfRange: undefined,
    gradeOutOfRange: '',
    isGradeOutOfRange: false,
  },
};

export const defaultQuestions = {
  ...defaultState,
  fieldConditions: [],
  fieldDisable: [],
  data: {
    questions: [],
  },
};

export const defaultQuestionsLaptop = {
  ...defaultState,
  outOfRangeValues: {},
  data: {
    questions: {
      group1: undefined,
      group2: undefined,
    },
  },
};

const tradeInSlice = createSlice({
  name: 'tradeIn',
  initialState: {
    types: defaultState,
    brands: defaultState,
    models: defaultState,
    grade: gradeDefaultState,
    questions: defaultQuestions,
    laptop: {
      questions: defaultQuestionsLaptop,
      items: {
        loading: false,
        data: undefined,
      }
    },
  },
  reducers: {
    setTypes(state, action) {
      state.types = action.payload;
    },
    setTypesLoading(state, action) {
      state.types.loading = action.payload;
    },
    setBrands(state, action) {
      state.brands = action.payload;
    },
    setBrandsLoading(state, action) {
      state.brands.loading = action.payload;
    },
    setModels(state, action) {
      state.models = action.payload;
    },
    setModelsLoading(state, action) {
      state.models.loading = action.payload;
    },
    setQuestions(state, action) {
      state.questions = { ...defaultQuestions, ...action.payload };
    },
    setQuestionsFieldDisable(state, action) {
      state.questions.fieldDisable = action.payload;
    },
    setQuestionsLoading(state, action) {
      state.questions.loading = action.payload;
    },
    setGrade(state, action) {
      state.grade = action.payload;
    },
    setGradeLoading(state, action) {
      state.grade.loading = action.payload;
    },
    setLaptopQuestions(state, action) {
      state.laptop.questions = { ...defaultQuestionsLaptop, ...action.payload };
    },
    setLaptopQuestionsLoading(state, action) {
      state.laptop.questions.loading = action.payload;
    },
    setLaptopItems(state, action) {
      state.laptop.items.data = action.payload;
    },
    setLaptopItemsLoading(state, action) {
      state.laptop.items.loading = action.payload;
    },
  },
});

export const {
  setTypes,
  setTypesLoading,
  setBrands,
  setBrandsLoading,
  setModels,
  setModelsLoading,
  setQuestions,
  setQuestionsFieldDisable,
  setQuestionsLoading,
  setGrade,
  setGradeLoading,
  setLaptopQuestions,
  setLaptopQuestionsLoading,
  setLaptopItems,
  setLaptopItemsLoading,
} = tradeInSlice.actions;
export default tradeInSlice.reducer;
