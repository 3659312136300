// Core
import React, { FC, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import Input from '../../../../components/_Form/Input';
import Button from '../../../../components/Button';
import useStyles from '../../styles';
import IdPlaceModal from './IdPlaceModal';
// Engine
import { postRepairStatusAsync } from '../../../../../engine/core/repairStatus/saga/asyncAction';
import { selectorsRepairStatus } from '../../../../../engine/core/repairStatus/selectors';
import { setError, setShowIdPlaceModal,clearValue as clearValueAction } from '../../../../../engine/core/repairStatus/slice';
import { composeValidators, isRequired } from '../../../../components/_Form/validators';

export const InfoFormNames = Object.freeze({
  receiptNumber: 'receiptNumber',
});

const InfoForm:FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const form = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();
  const loading = useSelector(selectorsRepairStatus.loading);
  const error = useSelector(selectorsRepairStatus.error);
  const clearValue = useSelector(selectorsRepairStatus.clearValue);
  const params = (new URL(window.location.href)).searchParams;
  const repairId = params.get('repairId');
  const prevRepairId = useRef(repairId);
  const onSubmit = (value: { [key: string]: string; }) => {
    const { [InfoFormNames.receiptNumber]: orderNumber } = value;
    dispatch(postRepairStatusAsync({ 'receiptNumber': orderNumber.replace('ИМ-','') }))
  }
  const showModal = () => {
    dispatch(setShowIdPlaceModal(true))
  }
  const onValidate = () => (
    { [InfoFormNames.receiptNumber]: error }
  );
  const inputOnChange = () => {
    if (error) {
      dispatch(setError(undefined))
    }
  }

  useEffect(() => {
    if (prevRepairId.current && repairId && repairId === prevRepairId.current && form?.current) {
      form.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles:true }))
    }
  }, [dispatch, repairId])

  useEffect(() => {
    if (clearValue) {
      window.repairForm?.mutators?.clearFormValue(InfoFormNames.receiptNumber, undefined);
      dispatch(clearValueAction(false));
    }
  }, [clearValue, dispatch])

  useEffect(() => () => { window.repairForm = undefined }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      mutators={{
        clearFormValue: (args, state) => {
          state.fields[args[0]]?.change(args[1]);
        }
      }}
      render={(
        {
          handleSubmit,
          submitting,
          pristine,
          form: repairForm
        }
      ) => {
        if (!window.repairForm) {
          window.repairForm = repairForm;
        }

        return <>
          <form ref={form} onSubmit={handleSubmit} className={classes.wrapper}>
            <Field
              name={InfoFormNames.receiptNumber}
              onChange={inputOnChange}
              component={Input}
              validate={composeValidators(isRequired)}
              startAdornment='ИМ-'
              defaultValue={repairId || undefined}
              number
              maxLength={9}
              label={t('repairStatus.infoForm.number')}
            />
            <Button fullWidth disabled={submitting || pristine || loading} loading={loading} submit>
              {t('orderStatus.infoForm.button')}
            </Button>
            <div className="order-status_repair_information">
              <span className="link" onClick={showModal} onKeyDown={showModal}>
                {t('repairStatus.infoForm.findNumberLink')}
              </span>
            </div>
          </form>
          <IdPlaceModal/>
        </>
      }}
    />
  )
}

export default InfoForm;
