// Core
import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Engine
import { SelectorsTradeIn } from '../../../../../engine/core/tradeIn/selectors';
import { setGrade, gradeDefaultState } from '../../../../../engine/core/tradeIn/slice';
// Parts
import useStyles from './styles';
import Question from '../Question';
// TS
import { MutatorsTypes } from '../../../../components/_Form/types';
import { QuestionValues } from '../../../../../engine/core/tradeIn/types';

const Questions: FC<MutatorsTypes & QuestionValues> = props => {
  const { values, mutators } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const questionsData = useSelector(SelectorsTradeIn.questions.date).questions[0];
  const questionsLists = questionsData ? Object.keys(questionsData).map(key => questionsData[key]) : [];

  useEffect(() => {
    dispatch(setGrade(gradeDefaultState));
  }, [dispatch]);

  return (
    <>
      {Boolean(questionsLists.length) &&
        questionsLists.map(
          (
            item: {
              title: string;
              questions: [
                {
                  question: string;
                  code: string;
                  type: 'radio';
                  answers: [
                    {
                      answer: string;
                      value: number;
                      image?: string;
                    },
                  ];
                },
              ];
            },
            key,
          ) => (
            <div key={item.title} className={classes.questions}>
              <h6 className={classes.questionsTitle}>{item.title}</h6>
              {item.questions.map(question => (
                <Question
                  mutators={mutators}
                  values={values}
                  key={question.code}
                  question={question.question}
                  answers={question.answers}
                  code={question.code}
                />
              ))}
            </div>
          ),
        )}
    </>
  );
};

export default Questions;
