import get from "lodash/get";

import * as types from "../actions/actionTypes";
import { CREDIT_SUB_TYPES } from "../../../../engine/constants";
import { GRAPH, FIRST_PAYMENT } from "../actions/actionTypes";

const inActiveCreditTypes = items => {
    const clonedItems = {
        ...items,
    };

    Object.keys(clonedItems).forEach(item => {
        clonedItems[item] = {
            ...clonedItems[item],
            ...{
                active: false,
            },
        };
    });

    return clonedItems;
};

const inActiveTargetCredit = (targets=[]) => {

    const result = targets.map( item => {
        return {
            ...item,
            active: false,
        }
    });

    return result;
};


const setCreditType = (items, payload) => {
    const clonedItems = {
        ...items,
    };

    Object.keys(clonedItems).forEach(item => {
        clonedItems[item] = {
            ...clonedItems[item],
            ...{
                active: item === payload.creditTypeCode ? payload.active : false,
            },
        };
    });

    return clonedItems;
};

const setTargetCreditType = (items, payload) => {

    return items && !!items.length
        ? items.map( item => ({
            ...item,
            active: item.bank_code_1c === payload.targetBankCode
                ? payload.active
                : false,
        }))
        : [];

};

const creditReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CART_CREDIT_SUCCESS:
            return {
                ...state,
                ...{
                    freeType: null,
                    paidType: null,
                },
                errorItems: null,
                ...action.payload,
            };

        case types.SET_CREDIT_SUB_TYPE:

            if (action.payload.isChangeTabCredit) {
                const subType = CREDIT_SUB_TYPES.FREE_TYPE == action.payload.data
                    ? CREDIT_SUB_TYPES.FREE_TYPE
                    : CREDIT_SUB_TYPES.PAID_TYPE;
                return {
                    ...state,
                    creditSubType: action.payload.data,
                    [subType]: {
                        ...state[subType],
                        items: !!inActiveCreditTypes(state[subType].items)
                            ? inActiveCreditTypes(state[subType].items)
                            : {},
                        targetItems: !!inActiveTargetCredit(state[subType].targetItems)
                            ? inActiveTargetCredit(state[subType].targetItems)
                            : [],
                    },
                }
            }
            return {
                ...state,
                creditSubType: action.payload.data,
            };

        case types.SET_CREDIT_TYPE_DATA:
            return {
                ...state,
                ...{
                    creditTypeData: action.payload,
                },
            };

        case types.SET_CART_CREDIT_ERROR:
            return {
                ...state,
                ...{
                    error: action.payload,
                },
            };

        case types.CLEAR_CART_CREDIT:
            return {
                ...state,
                ...{
                    freeType: null,
                    paidType: null,
                    banks: null,
                    creditSubType: null,
                    creditTypeData: null,
                    error: null,
                },
            };

        case types.TOGGLE_CREDIT_TYPE:
            return {
                ...state,
                ...{
                    [CREDIT_SUB_TYPES.FREE_TYPE]: (() => {
                        return state[CREDIT_SUB_TYPES.FREE_TYPE] ? {
                            ...state[CREDIT_SUB_TYPES.FREE_TYPE],
                            ...{
                                items: inActiveCreditTypes(state[CREDIT_SUB_TYPES.FREE_TYPE].items),
                            },
                        } : null;
                    })(),
                    [CREDIT_SUB_TYPES.FREE_TYPE]:

                        state[CREDIT_SUB_TYPES.FREE_TYPE] && action.payload.isTarget
                            ? {
                                ...state[CREDIT_SUB_TYPES.FREE_TYPE],
                                ...{
                                    targetItems: inActiveTargetCredit(state[CREDIT_SUB_TYPES.FREE_TYPE].targetItems),
                                },
                            }
                            :  state[CREDIT_SUB_TYPES.FREE_TYPE] ? {
                                ...state[CREDIT_SUB_TYPES.FREE_TYPE],
                                ...{
                                    items: inActiveCreditTypes(state[CREDIT_SUB_TYPES.FREE_TYPE].items),
                                },
                            } : null,

                    [CREDIT_SUB_TYPES.PAID_TYPE]:
                        state[CREDIT_SUB_TYPES.PAID_TYPE] && action.payload.isTarget
                            ? {
                                ...state[CREDIT_SUB_TYPES.PAID_TYPE],
                                ...{
                                    targetItems: inActiveTargetCredit(state[CREDIT_SUB_TYPES.PAID_TYPE].targetItems),
                                },
                            }
                            : state[CREDIT_SUB_TYPES.PAID_TYPE]
                                ? {
                                    ...state[CREDIT_SUB_TYPES.PAID_TYPE],
                                    ...{
                                        items: inActiveCreditTypes(state[CREDIT_SUB_TYPES.PAID_TYPE].items),
                                    },
                                }
                                : null,
                    [action.payload.creditSubType]:
                        action.payload.isTarget
                            ? {
                                ...state[action.payload.creditSubType],
                                ...{
                                    items: setCreditType(state[action.payload.creditSubType].items, action.payload),
                                    targetItems: setTargetCreditType(state[action.payload.creditSubType].targetItems, action.payload),
                                },
                            }
                            : {
                                ...state[action.payload.creditSubType],
                                ...{
                                    targetItems: setTargetCreditType(state[action.payload.creditSubType].targetItems, action.payload),
                                    items: setCreditType(state[action.payload.creditSubType].items, action.payload),
                                },
                            },
                },
            };

        case types.GET_CREDIT_FORM_BY_TYPE_CODE_SUCCESS:
            if (action.payload.target) {
                return {
                    ...state,
                    targetForm: action.payload.form,
                };
            } else {
                return {
                    ...state,
                    ...{
                        [action.payload.creditSubType]: {
                            ...state[action.payload.creditSubType],
                            ...{
                                items: {
                                    ...state[action.payload.creditSubType].items,
                                    ...{
                                        [action.payload.creditTypeCode]: {
                                            ...state[action.payload.creditSubType].items[action.payload.creditTypeCode],
                                            ...{
                                                form: action.payload.form,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                };
            }


        case types.SET_CREDIT_TARGET_DATA:
            return {
                ...state,
                ...{
                    creditTargetData: action.payload,
                },
            };

        case types.TOGGLE_CREDIT_TARGET_TYPE:
            return {
                ...state,
                [action.payload.creditSubType]: {
                    ...state[action.payload.creditSubType],
                    targetItems: {
                        ...state[action.payload.creditSubType].targetItems,
                        targetItems: setTargetCreditType(state[action.payload.creditSubType].targetItems, action.payload),
                    }
                },
            };

        case types.SET_CREDIT_NUMBER_MONTHS:
            return {
                ...state,
                [action.payload.creditSubType]: {
                    ...state[action.payload.creditSubType],
                    creditNumberMonths: action.payload.creditNumberMonths,
                }
            };

        case GRAPH.CREDIT_GRAPH_SUCCESS:
            const creditTexts = action.payload && action.payload.texts || {}
            return {
                ...state,
                ...action.payload,
                texts: {
                    ...state.texts,
                    ...creditTexts,
                },
            };

        case FIRST_PAYMENT.SET:
            return {
                ...state,
                firstPaymentValue: action.payload,
            };

        case types.SET_WAIT_GRAPH:
            return {
                ...state,
                isWaitGraph: action.payload,
            };

        case types.SET_WAIT_CART:
            return {
                ...state,
                isWaitCart: action.payload,
            };


        default:
            return state;
    }
};

const initialState = null;

export default creditReducer;
