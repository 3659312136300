import * as actionTypes from "../actions/actionTypes";
import {setFieldValueToCookieByGroup} from "../helpers/functions";
import {COOKIES_DATA_GROUP} from "../../../../engine/constants";

const initialState = {
    selections: {
        contactData: null,
        deliveryData: null,
        paymentData: {
            paymentMethod: null,
        },
        additionalData: null,
    },
    errors: null,
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STEP_TO_ORDER:
            return {
                ...state,
                ...{
                    selections: {
                        ...state.selections,
                        ...{[action.payload.stepDataName]: initialState.selections[action.payload.stepDataName]},
                    },
                },
            };

        case actionTypes.SET_STEP_TO_ORDER:
            return {
                ...state,
                ...{
                    selections: {
                        ...state.selections,
                        ...{[action.payload.stepDataName]: {...(state && state.selections && state.selections[action.payload.stepDataName] || null), ...action.payload.values}}
                    },
                },
            };

        case actionTypes.CREATE_ORDER_SET_ERRORS:
            return {
                ...state,
                ...{
                    errors: action.payload,
                },
            };

        case actionTypes.SET_ORDER_PROGRESS:
            return {
                ...state,
                ...{
                    progress: action.payload,
                },
            };

        case actionTypes.CREATE_ORDER_SUCCESS:
            return state;

        case actionTypes.CHECKOUT_CONTACTS.CHANGE_PHONE:
            setFieldValueToCookieByGroup('phone', action.payload, COOKIES_DATA_GROUP.CHECKOUT_CONTACTS_STEP);

            return {
                ...state,
                ...{
                    selections: {
                        ...state.selections,
                        contactData: {
                            ...state.selections.contactData,
                            phone: action.payload
                        },
                    }
                },
            };
        default:
            return state;
    }
}

export default orderReducer;
