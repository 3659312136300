// Core
import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
// Parts
import { Typography } from '@mui/material';
import useStyles from './styles';
import Select from '../../components/_Form/Select';
import AutoSave from '../../components/_Form/AutoSave';
import { composeValidators, isRequired } from '../../components/_Form/validators';
import { Questions, QuestionsLaptop } from './components/Questions';
import Propose from './components/Propose';
// Engine
import { MemoSelectorsTradeIn, SelectorsTradeIn } from '../../../engine/core/tradeIn/selectors';
import {
  setQuestions,
  defaultQuestions,
  setBrands,
  setModels,
  defaultState,
  setGrade, gradeDefaultState
} from '../../../engine/core/tradeIn/slice';
import {
  postTypesAsync,
  postBrandsAsync,
  postModelsAsync,
  postQuestionsAsync,
  postGradeAsync,
} from '../../../engine/core/tradeIn/saga/asyncAction';

export const TradeInFormNames = Object.freeze({
  type: 'type',
  brand: 'brand',
  models: 'product_id',
});

const TradeIn: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const typesOptions = useSelector(MemoSelectorsTradeIn.types.getDataForOptions);
  const typesLoading = useSelector(SelectorsTradeIn.types.loading);
  const brandOptions = useSelector(MemoSelectorsTradeIn.brands.getDataForOptions);
  const brandLoading = useSelector(SelectorsTradeIn.brands.loading);
  const { gradeOutOfRange, codeOutOfRange } = useSelector(SelectorsTradeIn.grade.date);
  const modelsOptions = useSelector(MemoSelectorsTradeIn.models.getDataForOptions);
  const modelsLoading = useSelector(SelectorsTradeIn.models.loading);
  const dispatch = useDispatch();

  const onSubmit = (currentValues: {
    product_id: { value?: number },
    type: { value?: string }
  }) => {
    const allValuesFiled = Object.values(currentValues).every(x => x !== null);
    const product_id = currentValues?.product_id?.value;
    const type = currentValues?.type?.value;

    if (allValuesFiled) {
      if (type === 'laptop') {
        if (!Boolean(gradeOutOfRange) && codeOutOfRange === undefined) {
          dispatch(
            postGradeAsync({
              answers: currentValues,
              product_id: type,
            }),
          );
        }
      } else if (product_id) {
        dispatch(
          postGradeAsync({
            answers: currentValues,
            product_id,
          }),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(postTypesAsync());
  }, [dispatch]);

  return (
    <>
      <Typography component="h1" variant="h1" className={classes.title}>
        {t('tradeIn.index.title')}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          [TradeInFormNames.type]: null,
          // [TradeInFormNames.models]: null,
          // [TradeInFormNames.brand]: null,
        }}
        mutators={{
          changeValue: (args, state) => {
            state.fields[args[0]]?.change(args[1]);
          },
        }}
        render={({ handleSubmit, values, form: { mutators }, errors }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <div className={classes.left}>
              {/* @ts-ignore */}
              <AutoSave debounce={1000} save={onSubmit} />
              <Field
                isSearchable={false}
                validate={composeValidators(isRequired)}
                name={TradeInFormNames.type}
                component={Select}
                options={typesOptions}
                isLoading={typesLoading}
                label={t('tradeIn.index.type')}
              />
              <OnChange name={TradeInFormNames.type}>
                {value => {
                  dispatch(setGrade(gradeDefaultState));
                  dispatch(setQuestions(defaultQuestions));
                  dispatch(setBrands(defaultState));
                  dispatch(setModels(defaultState));
                  Object.keys(values).forEach(name => {
                    if (name !== TradeInFormNames.type) {
                      mutators.changeValue(name, undefined);
                    }
                  });
                  if (value.value === 'laptop') {
                    dispatch(postQuestionsAsync({ type: value.value }));
                  } else {
                    dispatch(
                      postBrandsAsync({
                        typeId: value.value,
                        limit: 999,
                        offset: '',
                      }),
                    );
                  }
                }}
              </OnChange>

              {brandOptions.length ? (
                <>
                  <Field
                    isSearchable={false}
                    validate={composeValidators(isRequired)}
                    name={TradeInFormNames.brand}
                    component={Select}
                    options={brandOptions}
                    isLoading={brandLoading}
                    label={t('tradeIn.index.manufacturer')}
                  />
                  <OnChange name={TradeInFormNames.brand}>
                    {value => {
                      if (value?.value) {
                        Object.keys(values).forEach(name => {
                          if (name !== TradeInFormNames.type && name !== TradeInFormNames.brand) {
                            mutators.changeValue(name, null);
                          }
                        });
                        dispatch(setQuestions(defaultQuestions));
                        dispatch(
                          postModelsAsync({
                            brand: value?.value,
                            // @ts-ignore
                            type: values[TradeInFormNames.type]?.value,
                            limit: 999,
                            query: '',
                          }),
                        );
                      }
                    }}
                  </OnChange>
                </>
              ) : null}

              {modelsOptions.length ? (
                <>
                  <Field
                    isSearchable={false}
                    validate={composeValidators(isRequired)}
                    name={TradeInFormNames.models}
                    component={Select}
                    options={modelsOptions}
                    isLoading={modelsLoading}
                    label={t('tradeIn.index.deviceModel')}
                  />
                  <OnChange name={TradeInFormNames.models}>
                    {value => {
                      if (value?.value) {
                        Object.keys(values).forEach(name => {
                          if (
                            name !== TradeInFormNames.type &&
                            name !== TradeInFormNames.brand &&
                            name !== TradeInFormNames.models
                          ) {
                            mutators.changeValue(name, null);
                          }
                        });
                        dispatch(setQuestions(defaultQuestions));
                        dispatch(
                          postQuestionsAsync({
                            // @ts-ignore
                            type: values[TradeInFormNames.type].value,
                          }),
                        );
                      }
                    }}
                  </OnChange>
                </>
              ) : null}

              {
                // @ts-ignore
                values[TradeInFormNames.type]?.value && values[TradeInFormNames.models]?.value && values[TradeInFormNames.brand]?.value
                && (
                  // @ts-ignore
                  <Questions values={values} mutators={mutators} />
                )
              }
              {
                // @ts-ignore
                (values[TradeInFormNames.type]?.value === 'laptop' && values[TradeInFormNames.models]?.value === undefined && values[TradeInFormNames.brand]?.value  === undefined)
                && (
                  // @ts-ignore
                  <QuestionsLaptop values={values} mutators={mutators} />
                )
              }
            </div>
            <Propose errors={errors}/>
          </form>
        )}
      />
    </>
  );
};

const TradeInPortal: FC = () => {
  const Root = document.getElementById('react_container__portal--trade_in');
  return Root ? createPortal(<TradeIn/>, Root) : null;
};

export default TradeInPortal;
