import * as actionTypes from "../actions/actionTypes";

const initialState = null;

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_ACTIVE_AUTH_FORM:
            return {...state, ...{activeAuthForm: action.payload}};

        case actionTypes.GET_USER_SUCCESS:
        case actionTypes.REG_USER_SUCCESS:
        case actionTypes.LOG_PHONE_USER_SUCCESS:
        case actionTypes.LOG_EMAIL_USER_SUCCESS:
        case actionTypes.VERIFY_PHONE_USER_SUCCESS:
        case actionTypes.GET_USER_AUTH_TEXTS_SUCCESS:
            return {...state, ...action.payload};
        
        default:
            return state;
    }
}

export default userReducer;