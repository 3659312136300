// Core
import React, { FC, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// Parts
import OrderStatusContent from './OrderStatusContent'
import OrderStatusNotOwnerContent from './OrderStatusNotOwnerContent'
import OrderStatusEmptyContent from './OrderStatusEmptyContent'
import CancelOrderModal from "./CancelOrderModal";
// Engine
import { selectorsOrderStatus } from "../../../../../engine/core/orderStatus/selectors";
import { setShowCancelOrderModal } from "../../../../../engine/core/orderStatus/slice";


export const OrderStatus: FC = () => {
  const orderData = useSelector(selectorsOrderStatus.data);
  const dispatch = useDispatch();
  const canceledUserOrder = {
    loading: useSelector(selectorsOrderStatus.canceledUserOrder.loading),
    informText: useSelector(selectorsOrderStatus.canceledUserOrder.informText),
  };
  const orderDataTexts = orderData?.texts;
  const [showContactDetails, setShowContactDetails] = useState(false);
  const informText = canceledUserOrder.informText || orderData?.canceledText;
  const statusClass = useMemo(() => {
    // const orange = [1, 2];
    // const blue = [3,  8, 10, 11, 12, 13];
    // const green = [4, 5];
    // const red = [6, 7, 9];
    // 1 -  В обработке
    // 2 -  Комплектуется
    // 3 -  Отправлен покупателю
    // 8 -  Отправлен покупателю
    // 10 - Отправлен
    // 11 - Ожидает подтверждения
    // 12 - Укомплектован
    // 13 - Ожидает отправки
    // 4 -  Готов к выдаче
    // 5 -  Заказ выполнен
    // 6 -  Отменен
    // 7 -  Отказ
    // 9 -  Ошибка
    const statusCode = Number(orderData.statusCode);

    switch (statusCode) {
      case 1:
      case 2: return 'status--prepare';
      case 4:
      case 5: return 'status--ok';
      case 3:
      case 8:
      case 10:
      case 11:
      case 12:
      case 13: return 'status--in-progress';
      case 6:
      case 7:
      case 9: return 'status--canceled';
      default: return '';
    }
  }, [orderData.statusCode]);

  if (!orderData) {
    return null;
  }

  const onCreateRoute = () => {
    // @ts-ignore
    window.open(orderData.routeUrl, '_blank').focus();
  }

  const onTrackOrder = () => {
    // @ts-ignore
    window.open(orderData.deliveryUrl, '_blank').focus();
  };

  const showModal = () => {
    dispatch(setShowCancelOrderModal(true))
  }

  const callSetShowContactDetails = () => setShowContactDetails(!showContactDetails);

  const orderStatusMainContent = () => {
    if (orderData.orderWith1C) {
      return <OrderStatusEmptyContent/>;
    }

    return (orderData.isOrderOwner && orderData.isAuth)
      ? <OrderStatusContent/>
      : <OrderStatusNotOwnerContent isAuth={Boolean(orderData.isAuth)}/>
  };

  return <div className="order-status_wrap">
    <div className="order-status_container">
      <div className="order-status_title">{orderDataTexts?.title} №{orderData?.orderNumber}</div>
      <div className="order-status_main">
        <div className="order-status_sidebar">
          <div className="order-status_info">
            <div className="order-status_info_title">
              {orderDataTexts?.infoTitle}
            </div>
            <div className="order-status_info_details">
              {Boolean(orderData.statusText) && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.status}
                  </div>
                  <div className="order-status_info_details_item_value">
                    <span className={`status ${statusClass}`}>
                      {orderData.statusText}
                    </span>
                  </div>
                </div>
              )}
              {Boolean(orderData.paymentText) && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.paymentType}
                  </div>
                  <div className="order-status_info_details_item_value">
                    {orderData.paymentText}
                  </div>
                </div>
              )}
              {Boolean(orderData.orderDate) && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.orderDate}
                  </div>
                  <div className="order-status_info_details_item_value">
                    {orderData.orderDate}
                  </div>
                </div>
              )}
              {!!orderData.deliveryText && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.deliveryType}
                  </div>
                  <div
                    className="order-status_info_details_item_value"
                    dangerouslySetInnerHTML={{ __html:
                           (orderData.isAuth && orderData.isOrderOwner)
                             ? orderData.deliveryTextFull || ''
                             : orderData.deliveryText || '' }}
                  />
                </div>
              )}
              {Boolean(orderData.city) && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.city}
                  </div>
                  <div className="order-status_info_details_item_value">
                    {orderData.city}
                  </div>
                </div>
              )}
              {Boolean(orderData.deliveryCode) && (
                <div className="order-status_info_details_item">
                  <div className="order-status_info_details_item_key">
                    {orderDataTexts?.orderNumber}
                  </div>
                  <div className="order-status_info_details_item_value">
                    {orderData.deliveryCode}
                  </div>
                </div>
              )}
              {orderData.isAuth && orderData.isOrderOwner && orderData?.isThirdPerson && (
                <>
                  <div className="order-status_info_details_item">
                    <div className="order-status_info_details_item_key">
                      {orderDataTexts?.receiver}
                    </div>
                    <div className="order-status_info_details_item_value">
                      {`${orderData?.thirdLastname} ${orderData?.thirdFirstname} ${orderData?.thirdMiddlename}`}
                    </div>
                  </div>
                  <div className="order-status_info_details_item">
                    <div className="order-status_info_details_item_key">
                      {orderDataTexts?.userPhone}
                    </div>
                    <div className="order-status_info_details_item_value">
                      {orderData?.thirdPhone}
                    </div>
                  </div>
                </>
              )}
            </div>
            {Boolean(orderData.routeUrl) && (
              <div className="order-status_info_route">
                  <span className="link link--dashed" onClick={onCreateRoute} onKeyDown={onCreateRoute}>
                    {orderDataTexts?.createRoute}
                  </span>
              </div>
            )}
            {Boolean(orderData.deliveryUrl) && (
              <button className="order-status_info_track-btn btn" type="button" onClick={onTrackOrder} onKeyDown={onTrackOrder}>
                {orderDataTexts?.tracking}
              </button>
            )}
          </div>
          {orderData.isAuth && orderData.isOrderOwner && !!(orderData.userName || orderData.userPhone || orderData.userEmail) && (
            <div className="order-status_info">
              <div
                className={`order-status_info_title closable ${showContactDetails ? 'is-open' : ''}`}
                onClick={callSetShowContactDetails}
                onKeyDown={callSetShowContactDetails}
              >
                {orderDataTexts?.userInfo}
              </div>
              {showContactDetails && (
                <div className="order-status_info_details">
                  {Boolean(orderData.userName) && (
                    <div className="order-status_info_details_item">
                      <div className="order-status_info_details_item_key">
                        {orderDataTexts?.userName}
                      </div>
                      <div className="order-status_info_details_item_value">
                        {orderData.userName}
                      </div>
                    </div>
                  )}
                  {Boolean(orderData.userPhone) && (
                    <div className="order-status_info_details_item">
                      <div className="order-status_info_details_item_key">
                        {orderDataTexts?.userPhone}
                      </div>
                      <div className="order-status_info_details_item_value">
                        {orderData.userPhone}
                      </div>
                    </div>
                  )}
                  {Boolean(orderData.userEmail) && (
                    <div className="order-status_info_details_item">
                      <div className="order-status_info_details_item_key">
                        Email
                      </div>
                      <div className="order-status_info_details_item_value">
                        {orderData.userEmail}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {orderData.isAuth && orderData.isOrderOwner && !!(orderData.isCancelable || informText) && (
            <div className="order-status_cancel-order-wrap">
              {Boolean(informText) && (
                <div className="order-status_cancel-order_notify">
                  {informText}
                </div>
              )}
              {orderData.isCancelable && !informText && (
                <button
                  type="button"
                  className="order-status_cancel-order_btn"
                  onClick={showModal}
                  disabled={canceledUserOrder.loading}
                >
                  {orderDataTexts?.cancelOrder}
                </button>
              )}
              <CancelOrderModal/>
            </div>
          )}
        </div>
        <div className="order-status_content">
          {orderStatusMainContent()}
        </div>
      </div>
    </div>
  </div>
}
