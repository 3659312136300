// Core
import React, { FC } from "react";
import { useSelector } from 'react-redux';
// Parts
import InfoForm from './components/InfoForm'
import OrderRepairInfo from './components/OrderRepairInfo'
// Engine
import { selectorsRepairStatus } from '../../../../engine/core/repairStatus/selectors'

const Repair: FC = () => {
  const data = useSelector(selectorsRepairStatus.data);
  return data?.receiptNumber
      ? <OrderRepairInfo />
      : <InfoForm />
}

export default Repair;
