import React, { FC } from 'react';
import TabsUi from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useStyles, { sx } from "./styles";

type tabPanelType = {
  index: number
  value: number
};

const TabPanel: FC<tabPanelType> = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (children)}
    </div>
  );
}

type tabsType = {
  child: {
    label: string,
    id: number,
    component: React.ReactNode
  }[],
  active?: number
  onChange?: (newValue: number) => unknown,
  onClick?: () => unknown,
  onClear?: () => void
}

const Tabs: FC<tabsType> = (props) => {
  const { child, active, onChange, onClick, onClear } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(active || child[0].id);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue)
    }
  };

  const handleClick = (targetId: number) => {
    onClick && onClick();
    value === targetId && onClear && onClear();
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TabsUi
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
        }}
        value={value}
        onChange={handleChange}
      >
        {child.map((item) => (
            <Tab
              sx={sx.tab}
              classes={{
                wrapped: classes.tabWrapped,
                selected: classes.tabSelected,
                root: classes.tabRoot
              }}
              key={item.id}
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              label={
                onClick
                  ? <div onClick={() => handleClick(item.id)} aria-hidden="true">{item.label}</div>
                  : item.label
              }
              id={`simple-tab-${item.id}`}
              wrapped
            />
        ))}
      </TabsUi>
      {child.map((item) => (
          <TabPanel value={value} index={item.id} key={item.id}>
            {item.component}
          </TabPanel>
      ))}
    </Box>
  );
}

Tabs.defaultProps = {
  active: undefined,
  onChange: undefined,
  onClick: undefined,
  onClear: undefined,
}

export default Tabs;
