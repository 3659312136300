import { DIIA } from "../actions/actionTypes";

const initialState = {
    isWaitingRequest: false,
    isShowDiia: true,
    isUserDataLoaded: false,
};

const diiaReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case DIIA.SET_DIIA_QR:
            return {
                ...state,
                ...action.payload,
                isWaitingRequest: false,
            }

        case DIIA.GET_DIIA_USER_INFO_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isWaitingRequest: false,
            }

        case DIIA.SET_USER_DATA_LOADED:
            return {
                ...state,
                isUserDataLoaded: action.payload,
            }

        case DIIA.SET_ERROR:
            return {
                ...state,
                error: action.payload,
                isWaitingRequest: false,
            }

        case DIIA.SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.payload,
            }

        case DIIA.TOGGLE_WAITING_REQUEST:
            return {
                ...state,
                isWaitingRequest: action.payload,
            };

        case DIIA.SET_SHOW_DIIA:
            return {
                ...state,
                isShowDiia: action.payload,
            };

        case DIIA.CLEAR_USER_DATA:
            return {
                ...initialState
            };

        default:
            return state;
    }
};

export default diiaReducer;