// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import { $borderColors } from "../../../../engine/constants/theme";

export const radioStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent !important',
    padding: '0 12px 0 0 !important',
    '& svg': {
      width: 19,
      height: 19,
      '&:first-child': {
        color: $borderColors.$border,
      },
      '&:last-child': {
        color: '#00B2F2',
      },
    },
  },
  wrapper: {
    fontSize: 16,
    color: '#222D4A',
    display: 'flex',
    alignItems: 'flex-start',
  },
  cursorPointer: {
    cursor: "pointer",
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  label: {
    marginTop: 2,
  },
}));
