import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from "redux-observable";
import rootReducer from '../reducers';
import {epicMiddlewareOptions, rootEpic} from '../middlewares/rootEpic';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware(epicMiddlewareOptions);
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(thunk, epicMiddleware),
    ...enhancers
);

export default function configureStore(initialState) {
    const createdStore = createStore(
        rootReducer(history),
        initialState,
        composedEnhancers
    );

    epicMiddleware.run(rootEpic);

    return createdStore;
};

