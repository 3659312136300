// Core
import React, { FC } from "react";
import { useSelector } from "react-redux";
// Engine
import { selectorsRepairStatus } from "../../../../../engine/core/repairStatus/selectors";

const OrderRepairInfo:FC = () => {
    const repairData = useSelector(selectorsRepairStatus.data);
    const statusColorType = repairData?.statusColorType || "in_progress" // statusColorType = in_progress/cancelled/completed
    const texts = repairData?.texts || {};

    return (
        <div className="order-status_repair-container">
            <div className="order-status_repair-main-title">{`${texts?.title  } №${repairData?.receiptNumber}`}</div>
            <div className="order-status_repair-info">
                <div className="order-status_repair-info-block status">
                    <div className="order-status_repair-title">{texts.infoTitle}</div>
                    <div className={`order-status_repair-status ${statusColorType}`}>{repairData?.statusText || ""}</div>
                </div>
                {
                    !!repairData?.city &&
                    <div className="order-status_repair-info-block">
                        <div className="order-status_repair-subtitle">{texts.city}</div>
                        <div className="order-status_repair-text" dangerouslySetInnerHTML={{ __html: repairData?.city }}/>
                    </div>
                }
                {
                    !!repairData?.deliveryText &&
                    <div className="order-status_repair-info-block">
                        <div className="order-status_repair-subtitle">{texts.place}</div>
                        <div className="order-status_repair-text" dangerouslySetInnerHTML={{ __html: repairData?.deliveryText }}/>
                    </div>
                }
            </div>
            {
                !!repairData?.description && (
                    <div className="order-status_repair-details">
                        <div
                          className="order-status_repair-title order-status_repair-details-title">{texts?.userInfo}</div>
                        <div className="order-status_repair-subtitle" dangerouslySetInnerHTML={{ __html: repairData?.description }}/>
                    </div>
                )
            }
        </div>
    )
};

export default OrderRepairInfo;
