import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

// Config
const saveMissingTranslate = process.env.REACT_APP_I18N_SAVE_MISSING_TRANSLATE === 'true';
const i18nDebug = process.env.REACT_APP_I18N_DEBUG === 'true';
const translateFilePath = process.env.REACT_APP_I18N_FILE_PATH;

// @ts-ignore
i18n
  .use(HttpApi)
  .use(detector)
  .use(initReactI18next)
  .init({
    nsSeparator: false,
    keySeparator: false,
    detection: {
      order: ['cookie'],
      lookupCookie: 'lang',
    },
    debug: i18nDebug,
    fallbackLng: {
      default: ['ru'],
    },
    saveMissing: saveMissingTranslate,
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
      wait: false,
      useSuspense: true,
    },
    backend: {
      loadPath: `${translateFilePath}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
