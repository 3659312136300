export async function request(url = '', method = 'POST', data = {}, queryData) {
  let requestUrl = url;

  if (queryData && typeof queryData === 'object') {
    let u = url;
    const lastSymbol = u.substr(u.length - 1);
    if (lastSymbol === '/') {
      u = u.substring(0, u.length - 1);
    }
    const newUrl = new URL(u);
    Object.keys(queryData).forEach(key => newUrl.searchParams.append(key, queryData[key]));
    requestUrl = newUrl;
  }

  const reqData = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  if (method === 'GET') {
    delete reqData.body;
  }

  const response = await fetch(requestUrl, reqData);

  return await response.json();
}

export const ajaxRequest = async ({ url, data = {}, method = 'GET', ...props}) => {
  return await $.ajax({
    type: method,
    url: url,
    data: data,
    ...props,
  });
};