import { combineReducers } from "redux";
import form from "redux-form/lib/reducer";
import cart from "./cartReducer";
import credit from "./creditReducer";
import cities from "./citiesReducer";
import citiesAutocomplete from "./citiesAutocompleteReducer";
import delivery from "./deliveryReducer";
import user from "./userReducer";
import progress from "./progressReducer";
import contacts from "./contactsReducer";
import initials from "./initialsReducer";
import payment from "./paymentReducer";
import order from "./orderReducer";
import dmsIntervals from "./dmsIntervalsReducer";
import modals from "./modalReducer";
import promocodes from "./promocodesReducer";
import services from "./servicesReducer";
import portal from "./portalReducer";
import markdown from "./markdownReducer";
import bonuses from "./bonusesReducer";
import productsData from "./productsDataReducer";
import diia from "./diiaReducer";

const rootReducer = history => combineReducers({
    portal,
    cart,
    credit,
    cities,
    citiesAutocomplete,
    delivery,
    user,
    form,
    progress,
    contacts,
    initials,
    payment,
    order,
    dmsIntervals,
    modals,
    promocodes,
    services,
    markdown,
    bonuses,
    productsData,
    diia,
});
export default rootReducer;
  