// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setQuestionsLoading, setQuestions, setLaptopQuestionsLoading, setLaptopQuestions } from '../../slice';
import { postQuestionsAsyncTypes } from '../asyncAction';
// TS
import { QuestionsType, FieldConditionsType, QuestionType } from '../../types';

export function* callPostQuestionsWorker(action: PayloadAction<postQuestionsAsyncTypes>): SagaIterator {
  const isLaptopType = action.payload.type === 'laptop';
  if (isLaptopType) {
    yield put(setLaptopQuestionsLoading(true));
  } else {
    yield put(setQuestionsLoading(true));
  }
  try {
    const response = isLaptopType
     ? yield call(axiosAPI.tradeIn.laptop.postQuestions, action.payload)
     : yield call(axiosAPI.tradeIn.postQuestions, action.payload);
    const questionsData = response?.data?.data?.questions[0];
    const questionsLists = questionsData ? Object.keys(questionsData).map(key => questionsData[key]) : [];
    const fieldConditions: FieldConditionsType = [];
    questionsLists.forEach((item: QuestionsType) =>
      item.questions.forEach(question =>
        question.answers.forEach(answer => {
          if (answer.show_conditions) {
            fieldConditions.push({ code: question.code, conditions: Object.keys(answer.show_conditions[0]) });
          }
        }),
      ),
    );

    if (isLaptopType) {
      const outOfRangeValues = response?.data?.data?.questions?.group1?.questions.reduce(
        (previousValue: QuestionType, currentValue: QuestionType) => {
          const itemValue: { [key: string]: string; } = {}
          currentValue.answers.forEach(item => {
            if (item.outOfRange) {
              itemValue[currentValue.code] = item.value.toString()
            }
          });
          return {
            ...previousValue,
            ...itemValue
          }
        },
        {}
      );
      yield put(
        setLaptopQuestions({
          ...response?.data,
          outOfRangeValues,
          data: response?.data?.data || {},
        })
      )
    } else {
      yield put(
        setQuestions({
          ...response?.data,
          fieldConditions,
          data: response?.data?.data || {},
        }),
      );
    }
  } catch (error) {
    console.warn(error);
  }
  if (isLaptopType) {
    yield put(setLaptopQuestionsLoading(false));
  } else {
    yield put(setQuestionsLoading(false));
  }
}
