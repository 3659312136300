import { BONUSES } from "../actions/actionTypes";
const initialState = {
    data: {},
    texts: {
        currency: "",
        name: "",
        tooltip: { 
            description: "", 
            linkHref: "", 
            linkText: "" ,
        },
    },
    isWaitingRequest: false,
    isBonusesLoaderShown: false,
};

const bonusesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case BONUSES.SET:
            return {
                ...state,
                texts: action.payload.texts,
                data: {
                    ...state.data,
                    ...action.payload.productsBonuses,
                },
            };
        case BONUSES.USER_VERIFICATION_SUCCESS:
        case BONUSES.PHONE_VERIFY_SUCCESS:
        case BONUSES.RESEND_SMS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                isWaitingRequest: false,
            };

        case BONUSES.USER_VERIFICATION_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    ...action.payload,
                },
                isWaitingRequest: false,
            };

        case BONUSES.RESEND_SMS_ERROR:
        case BONUSES.PHONE_VERIFY_ERROR:
            return {
                ...state,
                error: {
                    ...state.error,
                    smsError: action.payload.smsError,
                },
                isNotAvailable: action.payload.isNotAvailable ? action.payload.isNotAvailable : false,
                isWaitingRequest: false,
            };

        case BONUSES.TOGGLE_WAITING_REQUEST:
            return {
                ...state,
                isWaitingRequest: action.payload,
            };
        case BONUSES.CLEAR_DATA:
            return {
                ...state,
                data: {},
            };

        case BONUSES.GET_USER_VALUE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                isWaitingRequest: false,
            };

        case BONUSES.TOGGLE_BONUSES_LOADER:
            return {
                ...state,
                ...{
                    isBonusesLoaderShown: action.payload
                },
            };

        default:
            return state;
    }
};

export default bonusesReducer;