import get from "lodash/get";
import * as types from "./actionTypes";
import {productWasRemoved, setCartProductsCount} from "../../../functions/cartEvents";
import {clearTopPriceData, updateBuyButtonsText} from "../helpers/functions";
import { axiosAPI } from "../../../../engine/config/axios";
const {GET_DATA_GRAPH_SUCCESS} = types.TOP_PRICE;
import {creditGraphSuccess, setWaitGraph} from "./creditActions";
import {getDeliveryMethodsSuccess} from "./deliveryActions";
import { GRAPH } from './actionTypes';
import {setCookie, getCookie} from '../../../functions/cookies';

export function getCartSuccess(data) {
    return {
        type: types.GET_CART_SUCCESS,
        payload: data,
    };
}

export function setRecoveryItems(data) {
    return {
        type: types.SET_RECOVERY_ITEMS,
        payload: data,
    };
}

export function clearRecoveryItems() {
    return {
        type: types.CLEAR_RECOVERY_ITEMS,
    };
}

export function setCartType(type) {
    return {
        type: types.SET_CART_TYPE,
        payload: type,
    };
}

export function getCartProductsDates(data) {
    return {
        type: types.GET_CART_PRODUCTS_DATES_SUCCESS,
        payload: data,
    };
}

export function getCart(dataJson) {
    return function(dispatch) {
        dispatch(setWaitCart(true));
        return axiosAPI.cart.getCart(dataJson)
            .then( cart => {
                let locationData = {};
                location.search.replace(/\??([^=]+)=([^&]+)/ig, (fullMatch, key, val) => {
                    locationData[key] = val;
                });
                const newCartData = cart.data.data || {};
                const cartData = Object.assign({}, newCartData, locationData);
                cartData.cartType = newCartData.cartType || '';
                dispatch(getCartSuccess(clearTopPriceData(cartData)));
                if (dataJson && dataJson.isCheckout) {
                  statusCartEvent(cartData);
                }
                updateBuyButtonsText();
                if (!cart.data.status) {
                    return cart.data;
                }
                return cartData;
            })
            .catch(error => {
                throw error;
            }).finally(() => {
                dispatch(setWaitCart(false));
            });
    };
}

export function removeCartItem(dataJson) {
    return function(dispatch, getState) {
        return axiosAPI.cart.removeCartItem(dataJson)
            .then(({data: cart}) => {
                productWasRemoved(dataJson);
                setCartProductsCount(cart.data.cartTotalCount);
                const prevCartState = getState().cart;
                dispatch(getCartSuccess(clearTopPriceData(cart.data)));
                statusCartEvent(cart.data);
                updateBuyButtonsText();
                return prevCartState;
            })
            .catch(error => {
                throw error;
            });
    };
}

export function updateCartItemCount(dataJson) {
    return function(dispatch) {
        return axiosAPI.cart.updateCartItemCount(dataJson)
            .then(({data: cart}) => {
                setCartProductsCount(cart.data.cartTotalCount);
                dispatch(getCartSuccess(clearTopPriceData(cart.data)));
                statusCartEvent(cart.data);
                return cart.data;
            })
            .catch(error => {
                throw error;
            });
    };
}

export function addProducts(dataJson) {
    return function(dispatch) {
        return axiosAPI.cart.addProducts(dataJson)
            .then(({data: cart}) => {
                setCartProductsCount(cart.data.cartTotalCount);
                dispatch(getCartSuccess(clearTopPriceData(cart.data)));
                statusCartEvent(cart.data);
                updateBuyButtonsText();
                return cart.data;
            })
            .catch(error => {
                throw error;
            });
    };
}

export function processProductsEvents(dataJson) {
    let products = [];
    for(let product in dataJson.products) {
        products.push({
            productKey: dataJson.products[product].productId.toString(),
            price: dataJson.products[product].price.toString(),
            quantity: dataJson.products[product].count.toString(),
            currency: 'UAH'
        });
    }
    return products;
}

export function statusCartEvent(dataJson) {
    eS('sendEvent', 'StatusCart', {
        'StatusCart': processProductsEvents(dataJson),
        'GUID': dataJson.guid
    });
    setCookie('currentCartGuid', dataJson.guid);
}

export function customerDataEvent(customerData = {}) {
    SendEventSc('CustomerData', {
        'CustomerData': {
            'user_email': customerData.email,
            'user_name': customerData.firstname+' '+customerData.lastname,
            'user_phone': customerData.phone,
        }
    });
}

export function SendEventSc(eventName, propsEvent) {
    const sendEventObj = {
        GeneralInfo : {
            'eventName': eventName,
            'datetime': new Date().getTime(),
            'siteId': 'E6E2EE0',
            'cookies': {
                'sc': getCookie('sc')
            },
            's_location': encodeURIComponent(window.location.href),
            'referrer': encodeURIComponent(document.referrer),
            'user_email': '',
        }
    };
    Object.assign(sendEventObj, propsEvent);

    let data = 'id=' + JSON.stringify(sendEventObj) + '&site=E6E2EE0';

    fetch('https://tracker.esputnik.com/browser/v2',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: "POST",
            body: data
        });
}

export function purchasedItemsEvent(orderId = '') {
    SendEventSc('PurchasedItems', {
        "PurchasedItems":{
            "OrderNumber": orderId.toString(),
            "GUID": JSON.parse(localStorage.getItem('esState')).tracking.statusCart.GUID
        }
    });
}

export const getDataGraphSuccess = payload => ({
    type: GET_DATA_GRAPH_SUCCESS,
    payload,
})


export const getDataGraphConcatAll = (payload, checkBonuses = false) => ({
    type: GRAPH.CREDIT_GRAPH_GET_ALL,
    payload: [payload, checkBonuses],
});

export const getDataGraphCancelPrev = payload => {
    return {
        type: GRAPH.CREDIT_GRAPH_GET_CANCEL_PREV,
            payload,
    }
};

export function getDataGraph(dataJson, isUpdateCredit = false, isWaitGraph = false) {
    return function(dispatch) {
        if (isUpdateCredit || isWaitGraph) {
          dispatch(setWaitGraph(true));
        }

        return axiosAPI.graphApi.graph(dataJson)
            .then(({data}) => {
                const newData = get(data, "data.models", {});
                dispatch(getDataGraphSuccess(newData));
                if (isUpdateCredit) {
                  const paymentStatus = get(newData, "paymentMethods.status", false);
                  const paymentData = get(newData, "paymentMethods.data.methods", []);
                  const currentPaymentCredit = paymentData.find(i => i.alias === "paymentCredit");
                  const data = currentPaymentCredit && currentPaymentCredit.data ? currentPaymentCredit.data : {};

                  if (paymentStatus){
                    dispatch(creditGraphSuccess(data));
                  }
                }

                const deliveryMethods = get(newData, "deliveryMethods.data", null) ;
                deliveryMethods && dispatch(getDeliveryMethodsSuccess(deliveryMethods))
            })
            .catch(error => {
                throw error;
            }).finally(() => {
                dispatch(setWaitGraph(false));
            });
    };
}

export const setWaitCart = payload => {
    return {
        type: types.SET_WAIT_CART,
        payload,
    }
};

export function getProductsDates(dataJson) {
    return function(dispatch) {
        return axiosAPI.cart.getProductsDates(dataJson)
            .then( data => {
                dispatch(getCartProductsDates(data.data.data));
                return data.data.data;
            })
            .catch(error => {
                throw error;
            });
    };
}
