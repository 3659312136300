import { useState, useRef, useEffect, RefObject } from 'react';

function useVisible(initialIsVisible: boolean): {
  ref: RefObject<HTMLDivElement>
  isVisible: boolean
  setIsVisible: (value: boolean) => void
} {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef<HTMLDivElement>(null);

  function handleClickOutside({ target }: MouseEvent) {
    if (ref.current && !ref.current.contains(target as Node)) {
      setIsVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
}

export default useVisible;
