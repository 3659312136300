// Core
import { createSlice } from '@reduxjs/toolkit';

export type OrderStatusItemAdditionalItemsType = {
  id?: string
  name: string
  price: string
}

export type OrderStatusItemType = {
  id?: string
  count: string
  img: string
  name: string
  url: string
  oldPrice: string
  price: string
  totalPrice: string
  services: OrderStatusItemAdditionalItemsType[]
  orderDataTexts: {
    additionalServices: string
  }
}

interface OrderStatusState {
  loading?: boolean,
  data: {
    canceledText?: string
    cashback?: string
    city?: string
    delivery?: string
    deliveryUrl?: unknown
    deliveryText?: string
    deliveryTextFull?: string
    deliveryType?: string
    deliveryCode?: string
    liftCost?: number
    routeUrl?: string
    freeDelivery?: string
    isAuth?: boolean
    isCancelable?: boolean
    isOrderOwner?: boolean
    items?: {
      [key: string]: OrderStatusItemType;
    }
    orderDate?: string
    orderNumber?: string
    orderSum?: string
    paymentText?: string
    paymentType?: string
    showOrderStatusLeadForm?: boolean
    statusCode?: number
    statusText?: string
    orderWith1C?: boolean
    texts?: {
      additionalServices?: string
      cancelOrder?: string
      cashbackText?: string
      city?: string
      createRoute?: string
      deliveryNumber?: string
      deliverySum?: string
      deliveryType?: string
      liftCost?: string
      infoTitle?: string
      notificationFormBtn?: string
      notificationFormText?: string
      notificationFormTitle?: string
      orderDate?: string
      orderNumber?: string
      orderSum?: string
      paymentType?: string
      questionForm?: object
      status?: string
      title?: string
      totalSum?: string
      tracking?: string
      userEmail?: string
      userInfo?: string
      userName?: string
      userPhone?: string
      receiver?: string
    }
    totalSum?: string
    userEmail?: string
    userName?: string
    userPhone?: string
    isThirdPerson?: boolean
    thirdFirstname?: string
    thirdLastname?: string
    thirdMiddlename?: string
    thirdPhone?: string
  }
  error?: unknown,
  notify?: unknown,
  status?: unknown,
  clearValue?: unknown,
  canceledUserOrder: {
    loading: boolean,
    informText?: string,
  },
  questionsForm: {
    loading: boolean,
    successText?: string,
    errorText?: string,
  },
  leads: {
    loading: boolean,
    successText?: string,
    errorText?: string,
  },
  showCancelOrderModal: boolean,
}

const initialState: OrderStatusState = {
  loading: false,
  data: {
  },
  error: undefined,
  notify: undefined,
  status: undefined,
  clearValue: undefined,
  canceledUserOrder: {
    loading: false,
    informText: undefined,
  },
  questionsForm: {
    loading: false,
    successText: undefined,
    errorText: undefined
  },
  leads: {
    loading: false,
    successText: undefined,
    errorText: undefined
  },
  showCancelOrderModal: false,
}

const orderStatusSlice = createSlice({
  name: 'orderStatus',
  initialState,
  reducers: {
    setDefault(state) {
      Object.keys(initialState).map((objectKey) => {
        // @ts-ignore
        state[objectKey] = initialState[objectKey];
        return undefined;
      });
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setNotify(state, action) {
      state.notify = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    clearValue(state, action) {
      state.clearValue = action.payload;
    },
    setQuestionsFormSuccessText(state, action) {
      state.questionsForm.successText = action.payload;
    },
    setQuestionsFormErrorText(state, action) {
      state.questionsForm.errorText = action.payload;
    },
    setQuestionsFormLoading(state, action) {
      state.questionsForm.loading = action.payload;
    },
    setOrderStatusLeadsSuccessText(state, action) {
      state.leads.successText = action.payload;
    },
    setOrderStatusLeadsErrorText(state, action) {
      state.leads.errorText = action.payload;
    },
    setOrderStatusLeadsLoading(state, action) {
      state.leads.loading = action.payload;
    },
    setCanceledUserOrderLoading(state, action) {
      state.canceledUserOrder.loading = action.payload;
    },
    setCanceledUserOrderInformText(state, action) {
      state.canceledUserOrder.informText = action.payload;
    },
    setShowCancelOrderModal(state, action) {
      state.showCancelOrderModal = action.payload;
    },
  },
});

export const {
  setDefault,
  setLoading,
  setData,
  setError,
  setNotify,
  setStatus,
  clearValue,
  setQuestionsFormSuccessText,
  setQuestionsFormErrorText,
  setQuestionsFormLoading,
  setOrderStatusLeadsSuccessText,
  setOrderStatusLeadsErrorText,
  setOrderStatusLeadsLoading,
  setCanceledUserOrderLoading,
  setCanceledUserOrderInformText,
  setShowCancelOrderModal
} = orderStatusSlice.actions;
export default orderStatusSlice.reducer;
