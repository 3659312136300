import React, {
  FC,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { currencyFormat } from '../../../../../../new-moyo/src/js/native/helpers/currency.js';
// Parts
import useVisible from '../../../hooks/useIsVisible';

type ProductServicesItemType = {
  description: string
  title: string
  group_popup_icon: string
  products: {
    bonusValue: number
    calculationType: number
    code1c: string
    description: string
    oldPrice: null | {
      'data-price': number
    }
    price: number
    productId: number
    title: string
    type: string
    cautionText: string
  }[]
  showInfoModal: (icon: string) => void
  setActiveService: (serviceId: number, isChecked: boolean, serviceActiveId: string) => void
  mobile: boolean,
  isActive: boolean,
  id: string,
}

const ProductServicesItem:FC<ProductServicesItemType> = (props) => {
  const {
    description, group_popup_icon, products = [], title, isActive,
    setActiveService, showInfoModal, mobile, id : serviceActiveId,
  } = props;

  const { ref, isVisible, setIsVisible } = useVisible(false);

  const [activeProduct, setActiveProduct] = useState(products[0] || {});

  const onSelectActiveItem = useCallback((productId) => {
    const activeItem = products.find((product) => (product.productId === productId));
    if (activeItem) {
      setActiveProduct(activeItem);
    }
    setIsVisible(false);
  }, [products, setIsVisible]);

  const onSelectService = (e: { target: HTMLInputElement }) => {
    setActiveService(activeProduct.productId, e.target.checked, serviceActiveId);
  };

  const oldPrice = useMemo(() => {
    const op = activeProduct.oldPrice || { 'data-price': undefined };
    const priceValue = op?.['data-price'];
    return priceValue ? currencyFormat(priceValue.toFixed()) : null;
  }, [activeProduct]);

  const cautionText = useMemo(() => activeProduct.cautionText, [activeProduct]);

  const callToggleVisible = () => setIsVisible(!isVisible);
  const callShowInfoModal = group_popup_icon ? () => showInfoModal(group_popup_icon) : undefined;

  return (
    <div className={`services_item ${isVisible ? 'active' : ''}`}>
      <div className="services_item_head">
        <div
          className={`services_item_head-title ${group_popup_icon ? 'has-modal' : ''}`}
          onKeyDown={callShowInfoModal}
          onClick={callShowInfoModal}
        >
          <span className="services_item_title">
            {title}
          </span>
          <span className="services_item_info">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875ZM7 12.0859C4.1918 12.0859 1.91406 9.8082 1.91406 7C1.91406 4.1918 4.1918 1.91406 7 1.91406C9.8082 1.91406 12.0859 4.1918 12.0859 7C12.0859 9.8082 9.8082 12.0859 7 12.0859Z"
                fill="currentColor"/>
              <path
                d="M6.34375 4.59375C6.34375 4.7678 6.41289 4.93472 6.53596 5.05779C6.65903 5.18086 6.82595 5.25 7 5.25C7.17405 5.25 7.34097 5.18086 7.46404 5.05779C7.58711 4.93472 7.65625 4.7678 7.65625 4.59375C7.65625 4.4197 7.58711 4.25278 7.46404 4.12971C7.34097 4.00664 7.17405 3.9375 7 3.9375C6.82595 3.9375 6.65903 4.00664 6.53596 4.12971C6.41289 4.25278 6.34375 4.4197 6.34375 4.59375ZM7.32812 6.125H6.67188C6.61172 6.125 6.5625 6.17422 6.5625 6.23438V9.95312C6.5625 10.0133 6.61172 10.0625 6.67188 10.0625H7.32812C7.38828 10.0625 7.4375 10.0133 7.4375 9.95312V6.23438C7.4375 6.17422 7.38828 6.125 7.32812 6.125Z"
                fill="currentColor"/>
            </svg>
            {!group_popup_icon && description && (
              <span className="services_item_info_text">
                {description}
              </span>
            )}
          </span>
        </div>
        {mobile && (
          <div className="services_item_price-wrap">
            {!!oldPrice && (
              <div className="services_item_oldprice">
                {oldPrice} грн
              </div>
            )}
            <div
              className="services_item_price">+ {currencyFormat(activeProduct.price.toString())} грн
            </div>
          </div>
        )}
      </div>
      <div className="services_item_container">
        <div className="services_item_details">
          <div className="services_item_checkbox">
            <div className="checkbox">
              <input
                className="checkbox_input"
                type="checkbox"
                name="checkbox"
                checked={isActive}
                onChange={onSelectService}
              />
              <div className="checkbox_field"/>
            </div>
          </div>
          <div className="services_item_select" ref={ref}>
            <div className="custom-select">
              <div className="custom-select">
                <div
                  className="custom-select_field"
                  onClick={callToggleVisible}
                  onKeyDown={callToggleVisible}
                >
                  {activeProduct.title}
                </div>
                {isVisible && (
                  <div className="custom-select_dropdown">
                    {products.map((product) => {
                      const handleSelectActiveItem = () => onSelectActiveItem(product.productId);
                      return (
                        <div
                          className="custom-select_item"
                          key={product.productId}
                          onClick={handleSelectActiveItem}
                          onKeyDown={handleSelectActiveItem}
                        >
                          <div className="custom-select_item_head">
                            <div className="custom-select_item_head_title">
                              {product.title}
                            </div>
                            <div className="custom-select_item_head_price">
                              {currencyFormat(product.price.toString())}
                            </div>
                          </div>
                          {!!product.description && (
                            <div className="custom-select_item_text">
                              {product.description}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!mobile && (
          <div className="services_item_price-wrap">
            {!!oldPrice && (
              <div className="services_item_oldprice">
                {oldPrice} грн
              </div>
            )}
            <div
              className="services_item_price">+ {currencyFormat(activeProduct.price.toString())} грн
            </div>
          </div>
        )}
      </div>
      {cautionText && <div className="services_item_caution">{cautionText}</div>}
    </div>
  );
};

export default ProductServicesItem;
