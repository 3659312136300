import { getCookie, setCookie } from "../../../functions/cookies";
import {COOKIES_DATA_GROUP, CREDIT_SUB_TYPES, DELIVERY_METHOD, FIRST_PAYMENT} from "../../../../engine/constants";
import { getCartProducts } from '../../../../../../new-moyo/src/js/native/helpers/storage';

export const formatThousands = ( numberOrString ) => {
    if ( typeof( numberOrString ) === "number" ) {
        numberOrString = numberOrString.toString(10);
    }
    if ( typeof( numberOrString ) === "string" ) {
        var remainder = numberOrString.length % 3;
        return (numberOrString.substr(0, remainder) + numberOrString.substr(remainder).replace(/(\d{3})/g, ' $1')).trim();
    }

    return false;
}

export const transformObjWithNumericKeysToArr = (obj) => {
    return Object.keys(obj).reduce( ( acc, key ) => {
        return [...acc, {
            id: Object.keys(obj[key])[0],
            title: obj[key][Object.keys(obj[key])[0]]
        }];
    }, []);
};

export const changeVisibleDateFormat = function( str ){
    var newStr, newArr;
    newArr = str.split("-")

    if ( newArr.length === 3 ) {
        newStr = "" + newArr[2] + "." + newArr[1] + "." + newArr[0];
    } else {
        newStr = str;
    }

    return newStr;
};

// cookiesGroupName come from src/engine/constants/index.js > COOKIES_DATA_GROUP object
export const setFieldValueToCookieByGroup = function( fieldName, newValue, cookiesGroupName ){
    let cookiesGroupObjByName = {};
    const isCookiesExist = getCookie(cookiesGroupName) && getCookie(cookiesGroupName).length;
    const prevCookiesGroupObjByName = isCookiesExist ? JSON.parse( getCookie(cookiesGroupName)) : {} ;

    typeof newValue === "object"
        ? cookiesGroupObjByName = {...newValue}
        : cookiesGroupObjByName[fieldName] = newValue;

    cookiesGroupObjByName = Object.assign( {}, prevCookiesGroupObjByName, cookiesGroupObjByName );
    const expDate = new Date();
    expDate.setDate( expDate.getDate() + 365 );
    const cookieOptions = {
        expires: expDate
    }
    setCookie( cookiesGroupName, JSON.stringify(cookiesGroupObjByName), cookieOptions );
}

export const removeDuplicateFromArr = arr => arr.filter( ( item, index ) => arr.indexOf(item) === index );

// get a unique list of offers by month for select
export const getMonthsOffersForSelect = (banks, isMobile, monthsTexts) => {
    let uniqueMonth = new Set();

    banks.forEach(bank => {
        Object.keys(bank.creditOffers).forEach(month => {
            uniqueMonth.add(month)
        })
    });

    return [...uniqueMonth]
        .sort((a, b) => a - b)
        .map(item => {
            return {
                id: item,
                name: isMobile ? `${item} ${monthsTexts[item]}` : item,
            }
        });
};

// get the match or the closest value
export const getUniqueBankOffer = (banks, paymentMonthly) => {
    const creditOffers = Object.keys(banks);
    let result = Object.values(banks)[0];

    if (paymentMonthly) {

        const maxAmountMonth = Math.min(...creditOffers.filter(month => month >= paymentMonthly));
        const minAmountMonth = Math.max(...creditOffers.filter(month => month <= paymentMonthly));

        if (banks[paymentMonthly]) {
            result = banks[paymentMonthly];
        } else if (banks[maxAmountMonth]) {
            result = banks[maxAmountMonth];
        } else if (banks[minAmountMonth]) {
            result = banks[minAmountMonth];
        }

    }
    return result;
};

export const getBankPeriods = bankOffers => {
    //filtering offers with regular prices
    let filteringItems = [];
    bankOffers.forEach( item => {
        const objKeys = Object.keys(item.creditOffers);
        if (!!item.creditOffers) {
            return objKeys.forEach( i => {
                if (item.creditOffers[i] && item.creditOffers[i].price_type !== "0") {
                    filteringItems.push(i);
                }
            });
        }
    });
    const filteredOffers = bankOffers.map( item => {
        const objKeys = Object.keys(item.creditOffers);
        if (!!item.creditOffers) {
            return objKeys.filter( i => {
                return item.creditOffers[i] && item.creditOffers[i].price_type === "0" && !filteringItems.includes(i);
            });
        }
        return [];
    });

    return !!filteredOffers.flat(Infinity).length
        ? filteredOffers
        : bankOffers.map( item => Object.keys(item.creditOffers)) ;
};

export const getDefaultPeriod = (arrs, creditSubType ) => {
    let resArr = [];
    let numberCoincidences = {};

    if ( arrs.length > 1 ) {

        for (let i = arrs[0].length - 1; i >= 0; i--) {

            let j = arrs.length - 1;
            for ( ; j > 0; j--) {
                if (arrs[j].indexOf(arrs[0][i]) == -1) {
                    break;
                }
            }

            // write down the match for all sentences
            if (j === 0) {
                resArr.push(arrs[0][i]);
            }

            //write down matches
            let k = arrs.length - 1;
            for ( ; k > 0; k--) {
                if (arrs[k].indexOf(arrs[0][i]) !== -1) {
                    if (numberCoincidences.hasOwnProperty(arrs[0][i])) {
                        numberCoincidences[arrs[0][i]] += 1;
                    } else {
                        numberCoincidences[arrs[0][i]] = 2;
                    }
                }
            }
        }

    } else {
        resArr = arrs.flat(Infinity).map( i => Number(i));
    }

    if (!!resArr.length) {
        if ( creditSubType === CREDIT_SUB_TYPES.PAID_TYPE ) {
            return Math.min(...resArr);
        } else {
            return Math.max(...resArr);
        }
    }

    if (resArr.length == 0) {
        if (!!Object.keys(numberCoincidences).length) {
            //displaying sentences in the absence of a complete match
            let result = creditSubType === CREDIT_SUB_TYPES.PAID_TYPE
                ? {month: Object.keys(numberCoincidences)[0], coincidence: numberCoincidences[Object.keys(numberCoincidences)[0]] }
                : {month: 0, coincidence: 0};

            for (let key in numberCoincidences) {
                if (creditSubType === CREDIT_SUB_TYPES.PAID_TYPE) {
                    if (result.coincidence > Number(numberCoincidences[key]) || result.month > Number(key)) {
                        result.coincidence = Number(numberCoincidences[key]);
                        result.month = Number(key);
                    }
                } else {
                    if (result.coincidence < Number(numberCoincidences[key]) || result.month < Number(key)) {
                        result.coincidence = Number(numberCoincidences[key]);
                        result.month = Number(key);
                    }
                }
            }

            return result.month

        } else {

            const result = arrs.flat(Infinity).map( i => Number(i));

            return  creditSubType === CREDIT_SUB_TYPES.PAID_TYPE
                ? Math.min(...result )
                : Math.max(...result );
        }
    }
};

export const defaultTargetPeriod = (bankOffers, creditType) => {
    const offers = Object.values(bankOffers);
    const allPeriods = [];
    offers.forEach((offer = {}) => {
        const creditOffers = offer.creditOffers || {};
        for (let creditOffer in creditOffers) {
            allPeriods.push(creditOffer);
        }
    });
    const creditPeriods = [...new Set(allPeriods)];
    const periodList = creditPeriods.map((period) => {
        let hasTopPrice = false;
        const offersData = offers.map((creditOffer = {}) => {
            const creditOfferPeriods = creditOffer.creditOffers || {};
            const currentCreditOffer = creditOfferPeriods[period];
            if(!currentCreditOffer) {
                return null;
            }
            if (currentCreditOffer.price_type !== '0') {
                hasTopPrice = true;
                return null;
            } else {
                return creditOffer;
            }
        }).filter(Boolean);
        if(hasTopPrice) {
            return null;
        }
        return {
            period,
            offersData,
        }
    }).filter(Boolean);

    if (!!periodList.length) {
        const periodListFreeType = [...periodList].sort((a, b) => {
            const aPeriod = Number(a.period);
            const bPeriod = Number(b.period);
            const aOffersData = a.offersData || [];
            const bOffersData = b.offersData || [];
            const n = aOffersData.length - bOffersData.length;
            if (n !== 0) {
                return n;
            }
            return aPeriod - bPeriod;
        });

        const periodListPaidType = [...periodList].sort((a, b) => {
            const aPeriod = Number(a.period);
            const bPeriod = Number(b.period);
            const aOffersData = a.offersData || [];
            const bOffersData = b.offersData || [];
            const n = bOffersData.length - aOffersData.length;
            if (n !== 0) {
                return n;
            }
            return aPeriod - bPeriod;
        });

        const periodListItem = creditType === CREDIT_SUB_TYPES.FREE_TYPE
            ? periodListFreeType[periodList.length - 1] || {period:0}
            : periodListPaidType[0] || {period:0};

        return periodListItem.period;
    }

    if (!!creditPeriods.length){
        return creditPeriods[0];
    }

};

export const getCurrentBankOffer = (bankOffers, period) => {
    let offersThisPeriod = [];
    bankOffers.forEach(offer => {
        if (offer.creditOffers[period]) {
            offersThisPeriod.push(offer.creditOffers[period]);
        }
    });

    let result = null;
    if (!!offersThisPeriod.length) {
        result = offersThisPeriod.reduce((accumulator, current) => {
            if (!accumulator) {
                return current;
            }
            if (
                accumulator.payment_monthly_value > current.payment_monthly_value
                || current.price_type !== "0"
            ) {
                return current;
            }
            return accumulator;
        });
    }

    return result;
}

export const getCreditIconsArray = ({bankOffers, currentMonthValue, banks, currentOffer}) => {
    const isDisabledIcon = ({currentMonthValue, monthArray = [], offers = [], currentOffer = {}}) => (!monthArray.find((element) => {
        const isCurrentTopPrice = currentOffer && currentOffer.price_type !== "0";
        const currentCode1c = currentOffer && currentOffer.code_1c;
        const offerCode1c = offers && offers[currentMonthValue] && offers[currentMonthValue].code_1c;
        const conditionDefault = element >= Number(currentMonthValue);

        if (currentCode1c === offerCode1c) {
            return true;
        }
        if (isCurrentTopPrice) {
            return conditionDefault;
        } else {
            return conditionDefault && offers[element] && offers[element].price_type == "0";
        }
    }));

    return bankOffers.map(item => {
        const offers = item.creditOffers;
        const monthArray = Object.keys(offers).map(i => Number(i));
        const firstOfferIndex = Object.keys(offers)[0];

        const information = offers[currentMonthValue]
          && offers[currentMonthValue].information
          || offers[firstOfferIndex]
          && offers[firstOfferIndex].information;

        return {
            url: banks[item.bank_code_1c].icon_url,
            id: `${Object.values(item).join()}-${item.name}`,
            information,
            isDisabled: isDisabledIcon({currentMonthValue, monthArray, offers, currentOffer}),
        }
    });
}

export const getActiveTopPriceOffer = ({creditTypes, propositionCode, typeCode}) => {
    const bank = creditTypes[typeCode];
    const result = bank && bank.creditOffers.find(proposition => {
        if(proposition.code_1c === propositionCode && proposition.price_type !== "0") {
            return proposition;
        }
        return null;
    })
    return !!result ? result : null;
}

export const clearTopPriceData = data => {
    const result = {...data};
    result.cartTotalOrderTopPrice = null;
    result.products.map(item => {
        item.topPriceValue = null;
        return item;
    });
    return result;
}

export const getIndexForFreeCreditOffer = data => {
    let dataDefaultValue = data.filter( item => item.price_type == "0" );
    if (data.length == dataDefaultValue.length) {
        return data.length -1;
    }
    const maxPaymentsQuantityOffer = dataDefaultValue.reduce((prev, current) => {
        return current.payments_quantity > prev.payments_quantity ? current : prev;
    },dataDefaultValue[0]);

    if (maxPaymentsQuantityOffer) {
        return data.findIndex(item => {
            if (item.payments_quantity == maxPaymentsQuantityOffer.payments_quantity) {
                return item
            }
        });
    }
    return -1;

}

export const getCommissionPercent  = (bankOffers, period) => {
    let offersThisPeriod = [];
    bankOffers.forEach(offer => {
        if (offer.creditOffers[period]) {
            offersThisPeriod.push(offer.creditOffers[period]);
        }
    });

    let result = {commission_percent: 0};
    if ( !!offersThisPeriod.length) {
        result = offersThisPeriod.reduce( (accumulator, current) => {
            if (!accumulator) {
                return current;
            }
            if ( accumulator.commission_percent > current.commission_percent ) {
                return current;
            }
            return accumulator;
        });
    }
    return result.commission_percent;
}

export const isNumber = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const validateFirstPaymentValue = (e, {firstPaymentLimit = 500}) => {
    const value = e.target.value;
    const filteredVal = value.split('').filter(el => isNumber(el)).join('');

    const isFirstNumberZero = filteredVal.length > 1 && filteredVal[0] == 0;

    if (filteredVal == '') {
        return '';
    } else if (isFirstNumberZero && filteredVal > 0) {
        return filteredVal.slice(1);
    } else if (!isNumber(filteredVal) || filteredVal > firstPaymentLimit || isFirstNumberZero){
        return null;
    }

    if (filteredVal >= 0 && isNumber(filteredVal)) {
        return filteredVal;
    }
    return null;
};

export const getGraphParams = ({cart = null, deliveryMethods = null, paymentMethods = null}) => {
    const params = {
        models: [
            {
                name: "cart",
                params: {
                    isCheckout: "0",//0 default value
                },
            },
            {
                name: "paymentMethods",
                params: {},
            },
            {
                name: "deliveryMethods",
                params: {},
            },
        ],
    };
    if (cart) {
        const cartModels = params.models.find(i => i.name == "cart").params;
        const cartModelsKeys = Object.keys(cart);
        cartModelsKeys.forEach(item => {
            if (cart[item] !== undefined) {
                cartModels[item] = cart[item];
            }
        });
    }
    if (deliveryMethods) {
        const deliveryMethodsModels = params.models.find(i => i.name == "deliveryMethods").params;
        const deliveryMethodsKeys = Object.keys(deliveryMethods);
        deliveryMethodsKeys.forEach(item => {
            if (deliveryMethods[item] !== undefined) {
                deliveryMethodsModels[item] = deliveryMethods[item];
            }
        });
    }
    if (paymentMethods) {
        const paymentMethodsModels = params.models.find(i => i.name == "paymentMethods").params;
        const paymentMethodsKeys = Object.keys(paymentMethods);
        paymentMethodsKeys.forEach(item => {
            if (paymentMethods[item] !== undefined) {
                paymentMethodsModels[item] = paymentMethods[item];
            }
        });
    }
    return params;
}

export const clearFirstPaymentData = ({setFirstPayment} = {setFirstPayment: null}) => {
    setCookie(FIRST_PAYMENT.VALUE, '' );
    if (setFirstPayment) {
        setFirstPayment('');
    }
}

export const countDigits = n => {
    if (typeof n === 'number') {
        let i = 0;
        for(; n > 1; i++) {
            n /= 10;
        }
        return i;
    }
    return 0;
}

export const clearHtml = html => {
    let content = document.createElement("DIV");
    content.innerHTML = html;
    return content.textContent || content.innerText || '';
}

// повертає об'єкт зі спільними ключами,
// значення по ключу береться з donorObject
export const getObjectsKeyBothIncludes = (objectToCheck = {}, donorObject = {}) => {
    const resObj = {};

    for (let key in donorObject){
        if (objectToCheck.hasOwnProperty(key)) {
            resObj[key] = donorObject[key]
        }
    }

    return [resObj, Object.keys(resObj).length];
}

export const parseIntervalInfo = intervalString => {
    if( !intervalString ){
        return [false, false]
    }

    let [date, ...time] = intervalString.split(";");
    date = changeVisibleDateFormat(date);
    time = time.join("-");

    return [date, time];
};

export const getMethodIdSelector = alias => {
    switch (alias){
        case DELIVERY_METHOD.TO_SHOP_ALIAS: {
            return 'shop_id';
        }
        case DELIVERY_METHOD.NOVA_POSHTA_ALIAS: {
            return 'np_id';
        }
        case DELIVERY_METHOD.NOVA_POSHTA_PARCEL_MACHINE_ALIAS: {
            return 'np_parcel_machine_id';
        }
        case DELIVERY_METHOD.JUST_IN_ALIAS: {
            return'justin_id';
        }
        default:
            return null;
    }
}

export const parseDeliveryTime = deliveryTimeStr => {
    if( !deliveryTimeStr ){
        return [false, false];
    }
    let [date, ...time] = deliveryTimeStr.split(" ");
    date = date.split('-').slice(1).reverse().join('.');
    time = time.join();
    return [date, time]
}

export const clearThirdPersonData = () => {
    const newValue = {
        isThirdPerson: 0,
        thirdFirstname: "",
        thirdLastname: "",
        thirdMiddlename: "",
        thirdPhone: "",
    }
    setFieldValueToCookieByGroup(null, newValue, COOKIES_DATA_GROUP.CHECKOUT_CONTACTS_STEP)
}

export const removeUnnecessaryFields = (selections, paymentMethod, deliveryMethod, isThirdPerson) => {
    return (paymentMethod === 'paymentCash' && (deliveryMethod === 'toShop' || isThirdPerson))
        ? {
            ...selections,
            contactData: {
                ...selections.contactData,
                lastname: '',
                middlename: '',
            }
        }
        : selections;
};

export const onPhoneFocus = (phoneMask = null, props = {}) => {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;

    if(!isAndroid) {
        phoneMask && phoneMask.onFocus(...props);
        return;
    }

    const onResize = () => {
        setTimeout(() => {
            phoneMask && phoneMask.onFocus(...props);
            window.removeEventListener("resize", onResize)
        }, 200);
    };

    window.addEventListener("resize", onResize);
};

// Add new fields Begin & ID to NP intervals
export const formatNPDataToDMS = (obj) => {
    let updatedObj = {};
    if(!obj || !Object.keys(obj).length) {
        return false;
    }

    Object.keys(obj).forEach(key => {
        updatedObj[key] = obj[key].map(time => {
                return {
                    ...time,
                    "Begin": time.Begin || time.Start,
                    "ID": time.ID ||
                        (+`${time.Start} - ${time.End}`
                            .replaceAll(" ", "")
                            .replaceAll(":", "")
                            .replaceAll("-", ""))
                }
            }
        )
    })

    return !!Object.keys(updatedObj).length && updatedObj;
}

export const scrollToElement = (selector = null, options = {}) => {
    const scrollTarget = document.querySelector(selector);
    scrollTarget && scrollTarget.scrollIntoView({
        behavior: "smooth",
        ...options
    });
}

export const formatDeliveryFloor = (string = '') => {
    let res = string.slice(0, 2);
    res = (Math.abs(Math.ceil(+res)) || '').toString();
    return [res, string !== res];
}

export const isLocalStorageAvailable = () => {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export function checkProductInCart(productId = '') {
    if (!productId) {
        return null;
    }

    const id = parseInt(productId, 10);
    const cartProducts = getCartProducts(localStorage);

    return cartProducts.filter(item => item && item.productId === id).length || false;
}

export function checkForceCheckoutProductInCart(productId = '') {
    const id = parseInt(productId, 10);
    const cartProducts = getCartProducts(localStorage);

    return cartProducts.filter(item => item && item.forceCheckout && item.productId === id).length ? 1 : null;
}

export function updateBuyButtonsText(delayMs = 200) {
    setTimeout(() => {
        const buttonsToUpdate = document.querySelectorAll('.js-buy[data-product-id], .js-product-buy[data-product-id]');

        if (buttonsToUpdate.length) {
            buttonsToUpdate.forEach((button) => {
                const { addedTitle = '', buyTitle = '', productId = '' } = button.dataset || {};
                const isProductInCart = checkProductInCart(productId);
                const isForceCheckout = checkForceCheckoutProductInCart(productId);

                button.dataset.forceCheckout = isForceCheckout;

                if (isProductInCart) {
                    button.classList.add('btn--checkout');
                    button.textContent = addedTitle;
                } else {
                    button.classList.remove('btn--checkout');
                    button.textContent = buyTitle;
                }
            });
        }
    }, delayMs);
}
