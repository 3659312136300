// Core
import React, { FC, useState, FormEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import TextField from '@mui/material/TextField';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import clsx from 'clsx';
// Parts
import ReactSelect, { ReactSelectType } from './ReactSelect';
import { selectStyles } from './styles';

export type SelectTypes = ReactSelectType & FieldRenderProps<{ [key: string]: string; }> & {
  label?: string;
  border?: boolean;
};

const MAX_ITEMS_BREAKPOINT = 6;

const Select: FC<SelectTypes> = props => {
  const { label, input, border, meta, options, isLoading, isSearchable, disabled = false } = props;
  const [open, setOpen] = useState(false);
  const classes = selectStyles();
  const isMoreThenItemsBreakpoint = options.length > MAX_ITEMS_BREAKPOINT;

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const onChangeReactSelect = (value: FormEvent) => {
    input.onChange(value);
    handleClickAway();
  };

  const inputRootClasses = clsx(classes.inputRoot, {
    [classes.border]: border,
    [classes.inputRootDisabled]: disabled,
  });

  const inputArrowClasses = clsx(classes.arrow, {
    [classes.disabledArrow]: disabled,
  });

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.wrapper}>
        <TextField
          disabled
          onClick={handleClick}
          fullWidth
          variant="filled"
          label={label}
          classes={{
            root: inputRootClasses,
          }}
          name={input.name}
          onBlur={input.onBlur}
          onChange={input.onChange}
          onFocus={input.onFocus}
          InputLabelProps={{ shrink: Boolean(input?.value?.label) }}
          value={input?.value?.label || ''}
        />
        <i className={inputArrowClasses} />
        {open && (
          <ReactSelect
            input={input}
            menuIsOpen
            autoFocus
            onChange={onChangeReactSelect}
            value={input?.value}
            meta={meta}
            options={options}
            isLoading={isLoading}
            type={isMoreThenItemsBreakpoint && isSearchable ? 'modal' : 'default'}
            isSearchable={isSearchable}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Select;
