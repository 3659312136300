// Core
import { createSelector } from 'reselect';
// Engine
import { RootState } from '../../init/store';

export const SelectorsTradeIn = Object.freeze({
  types: {
    loading: (state: RootState) => state.tradeIn.types.loading,
    date: (state: RootState) => state.tradeIn.types.data,
    error: (state: RootState) => state.tradeIn.types.error,
    notify: (state: RootState) => state.tradeIn.types.notify,
    status: (state: RootState) => state.tradeIn.types.status,
  },
  brands: {
    loading: (state: RootState) => state.tradeIn.brands.loading,
    date: (state: RootState) => state.tradeIn.brands.data,
    error: (state: RootState) => state.tradeIn.brands.error,
    notify: (state: RootState) => state.tradeIn.brands.notify,
    status: (state: RootState) => state.tradeIn.brands.status,
  },
  models: {
    loading: (state: RootState) => state.tradeIn.models.loading,
    date: (state: RootState) => state.tradeIn.models.data,
    error: (state: RootState) => state.tradeIn.models.error,
    notify: (state: RootState) => state.tradeIn.models.notify,
    status: (state: RootState) => state.tradeIn.models.status,
  },
  questions: {
    loading: (state: RootState) => state.tradeIn.questions.loading,
    fieldConditions: (state: RootState) => state.tradeIn.questions.fieldConditions,
    fieldDisable: (state: RootState) => state.tradeIn.questions.fieldDisable,
    date: (state: RootState) => state.tradeIn.questions.data,
    error: (state: RootState) => state.tradeIn.questions.error,
    notify: (state: RootState) => state.tradeIn.questions.notify,
    status: (state: RootState) => state.tradeIn.questions.status,
  },
  grade: {
    loading: (state: RootState) => state.tradeIn.grade.loading,
    date: (state: RootState) => state.tradeIn.grade.data,
    error: (state: RootState) => state.tradeIn.grade.error,
    notify: (state: RootState) => state.tradeIn.grade.notify,
    status: (state: RootState) => state.tradeIn.grade.status,
  },
  laptop: {
    questions: {
      loading: (state: RootState) => state.tradeIn.laptop.questions.loading,
      date: (state: RootState) => state.tradeIn.laptop.questions.data,
      outOfRangeValues: (state: RootState) => state.tradeIn.laptop.questions.outOfRangeValues,
      error: (state: RootState) => state.tradeIn.laptop.questions.error,
      notify: (state: RootState) => state.tradeIn.laptop.questions.notify,
      status: (state: RootState) => state.tradeIn.laptop.questions.status,
    },
    items: {
      loading: (state: RootState) => state.tradeIn.laptop.items.loading,
      date: (state: RootState) => state.tradeIn.laptop.items.data,
    }
  },
});

export const MemoSelectorsTradeIn = Object.freeze({
  types: {
    getDataForOptions: createSelector([SelectorsTradeIn.types.date], type =>
      type.map((item: { title: string; id: string }) => ({
        label: item?.title,
        value: item?.id,
      })),
    ),
  },
  brands: {
    getDataForOptions: createSelector([SelectorsTradeIn.brands.date], brand =>
      brand.map((item: { title: string; id: string }) => ({
        label: item?.title,
        value: item?.id,
      })),
    ),
  },
  models: {
    getDataForOptions: createSelector([SelectorsTradeIn.models.date], model =>
      model.map((item: { title: string; id: string }) => ({
        label: item?.title,
        value: item?.id,
      })),
    ),
  },
});
