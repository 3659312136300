// Core
import React, { FC } from "react";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// Parts
import Order from './Order'
import Repair from './Repair/index'
import Tabs from '../../components/Tabs'
import { setDefault as setDefaultRepairStatus, clearValue as clearRepairValue } from "../../../engine/core/repairStatus/slice";
import { setDefault as setDefaultOrderStatus, clearValue as clearOrderValue } from "../../../engine/core/orderStatus/slice";

const Status: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = Object.fromEntries(new URLSearchParams(window.location.search || ''));

  const child = [
    {
      id: 0,
      label: t('orderStatus.tab.status'),
      component: <Order />,
    },
    {
      id: 1,
      label: t('orderStatus.tab.repair'),
      component: <Repair />,
    }
  ];

  const handleClear = () => {
    const newUrl = `${window.location.origin + window.location.pathname  }`;
    window.history.replaceState( {} , '', newUrl );
    dispatch(setDefaultRepairStatus());
    dispatch(setDefaultOrderStatus());
  }

  const handleClearField = () => {
    dispatch(clearRepairValue(true));
    dispatch(clearOrderValue(true));
  }

  return <Tabs
    child={child}
    onClick={handleClear}
    onChange={handleClear}
    onClear={handleClearField}
    active={
      params?.repairId !== undefined ? child[1].id : child[0].id
    }
  />
}

const StatusPortal: FC = () => {
  const Root = document.getElementById('react_container__portal--status');
  return Root ? createPortal(<Status/>, Root) : null;
};

export default StatusPortal;
