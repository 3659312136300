// Engine
import { RootState } from '../../init/store';

export const selectorsRepairStatus = Object.freeze({
  loading: (state: RootState) => state.repairStatus.loading,
  status: (state: RootState) => state.repairStatus.status,
  notify: (state: RootState) => state.repairStatus.notify,
  data: (state: RootState) => state.repairStatus.data,
  error: (state: RootState) => state.repairStatus.error,
  showIdPlaceModal: (state: RootState) => state.repairStatus.showIdPlaceModal,
  clearValue: (state: RootState) => state.repairStatus.clearValue,
});
