// Core
import React, { FC } from "react";
import clsx from "clsx";
// Parts
import Box from '@mui/material/Box';
import useStyles from './styles';

type buttonType = {
  submit?: boolean,
  fullWidth?: boolean,
  disabled?: boolean,
  loading?: boolean,
  variant?: 'secondary',
}

const Button: FC<buttonType> = (props) => {
  const { submit, children, disabled, loading, fullWidth, variant } = props;
  const classes = useStyles();
  const className = clsx(classes.button, {
    [classes.loading]: loading,
    [classes.secondary]: variant,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
  })

  return <button disabled={disabled} type={submit ? "submit" : "button"} className={className}>
      <>
        {loading && <span className={classes.loader} />}
        {loading ? <Box sx={{ opacity: 0 }}>{children}</Box> : children}
      </>
  </button>
}

Button.defaultProps = {
  submit: false,
  fullWidth: false,
  disabled: false,
  loading: false,
  variant: undefined,
};

export default Button;
