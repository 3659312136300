import * as types from "../actions/actionTypes";

const citiesReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.GET_CITIES_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const initialState = null;

export default citiesReducer;