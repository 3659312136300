// Core
import React, { FC, memo } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import clsx from 'clsx';
// Parts
import useStyles from './styles';
import { TradeInFormNames } from '../../index';
import { composeValidators, isRequired } from '../../../../components/_Form/validators';
import Radio from '../../../../components/_Form/Radio';
// Engine
import {
  setQuestionsFieldDisable,
  setGrade,
  gradeDefaultState,
  defaultQuestions,
} from '../../../../../engine/core/tradeIn/slice';
import { SelectorsTradeIn } from '../../../../../engine/core/tradeIn/selectors';
// TS
import { AnswerType, QuestionType } from '../../../../../engine/core/tradeIn/types';
import { MutatorsTypes } from '../../../../components/_Form/types';

const Question: FC<QuestionType & MutatorsTypes> = props => {
  const { code, answers, question, values, mutators } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const fieldDisable = useSelector(SelectorsTradeIn.questions.fieldDisable);
  const outOfRangeValues = useSelector(SelectorsTradeIn.laptop.questions.outOfRangeValues);
  const { gradeOutOfRange, price } = useSelector(SelectorsTradeIn.grade.date);
  const answersOutOfRange = answers.filter(item => item.outOfRange !== undefined);
  // @ts-ignore
  const isLaptopType = values[TradeInFormNames.type].value === 'laptop';
  const questionAnswerClasses = clsx(classes.questionAnswerWrapper, {
    [classes.questionAnswerWrapperBool]: answers.length === 2,
  });

  const resetGrade = () => {
    if (isLaptopType) {
      return;
    }
    dispatch(setGrade({ ...gradeDefaultState, loading: true }));
  };

  return (
    <div className={classes.question}>
      {Boolean(question) && <p className={classes.questionText}>{question}</p>}
      <div className={questionAnswerClasses}>
        {answers.map((answer: AnswerType) => {
          const conditions = answer?.show_conditions?.[0];
          // @ts-ignore
          const disabled = fieldDisable.includes(code)
            || (price !== 0 && (answersOutOfRange[0]?.value.toString() !== values[code]) && answersOutOfRange[0]?.value !== undefined && isLaptopType && gradeOutOfRange)
            || (price !== 0 && answersOutOfRange.length === 0 && gradeOutOfRange && isLaptopType)
            || (price === 0 && answersOutOfRange.length === 0 && isLaptopType && gradeOutOfRange === undefined);
          const labelClasses = clsx(classes.questionLabel, {
            [classes.questionLabelWithImg]: Boolean(answer.image),
            [classes.questionLabelDisabled]: disabled,
          });
          if (conditions) {
            const valuesMeetCondition = Object.keys(conditions).map(itemKey =>
              conditions[itemKey].includes(Number.parseInt(values[itemKey], 10)),
            );
            if (!valuesMeetCondition.every(Boolean)) {
              if (answer.value?.toString() === values[code]?.toString() && values[code] !== null) {
                mutators.changeValue(code, null);
              }
              return null;
            }
          }

          return (
            <label className={labelClasses} key={`${code}_${answer.value.toString()}`}>
              <Field
                name={code}
                validate={composeValidators(isRequired)}
                allowNull
                type="radio"
                disabled={Boolean(disabled)}
                component={Radio}
                labelType="span"
                onChangeExtension={resetGrade}
                value={answer.value.toString()}
                label={answer.answer}
              />
              <OnChange name={code}>
                {(value, prevValue) => {
                  if (value !== prevValue) {
                    if (gradeOutOfRange && price === 0) {
                      dispatch(setGrade({ ...gradeDefaultState, loading: true }));
                    }
                    if (
                      (value === (answer.value).toString() && answer.outOfRange)
                      || (value === (answer.value).toString() && answer.outOfRange === 0)
                    ) {
                      if (answer.outOfRange === 0) {
                        dispatch(setGrade({
                          data: {
                            codeOutOfRange: code,
                            price: 0,
                          },
                        }));
                      } else {
                        dispatch(setGrade({
                          data: {
                            codeOutOfRange: code,
                            isGradeOutOfRange: true,
                            gradeOutOfRange: answer.outOfRange,
                          },
                        }));
                      }
                    } else if (answer.outOfRange || answer.outOfRange === 0) {
                      const itemsTest: string[] = []
                      Object.entries(outOfRangeValues).forEach(([outKey, outValue]) => {
                        if (values[outKey] !== outValue) {
                          itemsTest.push(values[outKey]);
                        }
                      });
                      if ((itemsTest.length === Object.keys(outOfRangeValues).length)) {
                        dispatch(setGrade({ ...gradeDefaultState, loading: true }));
                      }
                    }

                    if (answer.disable_questions && values[code].toString() === answer.value.toString()) {
                      dispatch(setQuestionsFieldDisable(answer.disable_questions));
                      answer.disable_questions.forEach(element => mutators.changeValue(element, '0'));
                    } else if (answer.disable_questions) {
                      dispatch(setQuestionsFieldDisable(defaultQuestions.fieldDisable));
                    }
                  }
                }}
              </OnChange>
              {answer.image && (
                <div className={classes.questionImg}>
                  <img src={`${process.env.REACT_APP_API_DOMAIN}/${answer.image}`} alt={answer.answer} />
                </div>
              )}
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Question);
