// Global file and config
import { emailRegExp } from '../../../../../new-moyo/src/js/native/constants/regExp.js';
// Core
import i18n from '../../../engine/config/i18n'

export const composeValidators =
  (...validators: { (data?: string): void; }[]) =>
  (value?: string): unknown =>
    // @ts-ignore
    validators.reduce((error, validator) => error || validator(value), undefined);

export const isRequired = (value?: string, message?: string): undefined | string => (value ? undefined : message || i18n.t('validators.requiredField'));
export const isEmail = (value?: string, message?: string): undefined | string => ( value && emailRegExp.test(value) ? undefined : message || i18n.t('validators.isEmail'));
export const isOrderNumberValid = (value?: string, message?: string): undefined | string => (value && value.match(/^(N)(\d{9})|\d{10}(_\d{1})|\d{10}$/) ? undefined : message || i18n.t('validators.orderNumberInvalid'));
export const isOrderNumberLengthValid = (value?: string, lengthArr?: number[]): boolean => !!(value && lengthArr?.includes(value.length));
