// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const sx = {
  tab: {
    textTransform: "none",
    fontSize: "24px",
    color: "#d3d3d4",
    fontWeight: "900",
    lineHeight: "1.15",
    padding: '0 0 8px 0',
    marginBottom: '25px',
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tabsFlexContainer: {
    justifyContent: "space-around"
  },
  tabsIndicator: {
    display: 'none'
  },
  tabWrapped: {
    maxWidth: '50% !important',
  },
  tabRoot: {
    textTransform: "none",
    fontSize: "24px",
    fontWeight: "900",
    lineHeight: "1.15",
    borderBottom: `4px solid transparent !important`,
    [theme.breakpoints.up('lg')]: {
      fontSize: "48px !important"
    },
  },
  tabSelected: {
    color: "#222d4a !important",
    borderBottom: `4px solid #00b2f2 !important`,
    transition: 'none !important',
  }
}));

export default useStyles;
