// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
// Engine
import { axiosAPI } from '../../../../config/axios';
// Core
import { setBrands, setBrandsLoading } from '../../slice';
// TS
import { postBrandsAsyncTypes } from '../asyncAction';

export function* callPostBrandsWorker(action: PayloadAction<postBrandsAsyncTypes>): SagaIterator {
  yield put(setBrandsLoading(true));
  try {
    const response = yield call(axiosAPI.tradeIn.postBrands, action.payload);
    yield put(
      setBrands({
        ...response?.data,
        data: response?.data?.data || [],
      }),
    );
  } catch (error) {
    console.warn(error);
  }
  yield put(setBrandsLoading(false));
}
