// Core
import { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { forOwn } from 'lodash';
// Engine
import { PayloadAction } from '@reduxjs/toolkit';
import { axiosAPI } from '../../../../config/axios';
// Core
import { setGrade, setGradeLoading } from '../../slice';
import { postGradeAsyncTypes } from '../asyncAction';

type AnswersPayloadType = {
  answers: {
    [key: string]: string | { value: string };
  };
  product_id?: number | string;
};

export function* callPostGradeWorker(action: PayloadAction<postGradeAsyncTypes>): SagaIterator {
  yield put(setGradeLoading(true));
  try {
    const normalizePayload: AnswersPayloadType = {
      // @ts-ignore
      answers: action.payload.product_id === 'laptop' ? { block2: {}, block1: {} } : {},
      product_id: action.payload.product_id,
    };
    let response;
    if (action.payload.product_id === 'laptop') {
      delete normalizePayload.product_id;

      forOwn(action.payload.answers, (answer: { value: string }, key) => {
        if (key.includes('group2_')) {
          if (answer?.value) {
            // @ts-ignore
            normalizePayload.answers.block2[key.split("_").pop()] = answer.value;
          } else {
            // @ts-ignore
            normalizePayload.answers.block2[key.split("_").pop()] = answer;
          }
        } else if (key !== 'type') {
          if (answer?.value) {
            // @ts-ignore
            normalizePayload.answers.block1[key] = answer.value;
          } else {
            // @ts-ignore
            normalizePayload.answers.block1[key] = answer;
          }
        }
      });
      response = yield call(axiosAPI.tradeIn.laptop.postGrade, normalizePayload);
    } else {
      forOwn(action.payload.answers, (answer: { value: string }, key) => {
        if (answer?.value) {
          normalizePayload.answers[key] = answer.value;
        } else {
          normalizePayload.answers[key] = answer;
        }
      });
      response = yield call(axiosAPI.tradeIn.postGrade, normalizePayload);
    }
    yield put(
      setGrade({
        ...response?.data,
        data: response?.data?.data || {},
      }),
    );
  } catch (error) {
    console.warn(error);
  }
  yield put(setGradeLoading(false));
}
