import * as types from "../actions/actionTypes";
import get from "lodash/get"

const PortalReducer = (state = initialState, action ) => {
    switch(action.type) {
        case types.GET_PORTAL_SUCCESS:
            return {
                ...state,
                ...action.payload.data.data
            }
        case types.GET_PORTAL_PRODUCTS_SUCCESS:
            const products = get(action.payload.data, "data.products", null );
            if ( products && products.length ) {
                const newComponentsArr = state.components.map( component => {
                    if ( component.type === "productBlock" ) {
                        return Object.assign( {},
                            component,
                            { data: {...component.data, ...action.payload.data.data }}
                        )
                    }
                    else return component;
                });
                return {
                    ...state,
                    components: newComponentsArr
                }
            } else {
                return state
            }
        case types.GET_PORTAL_MORE_PRODUCTS_SUCCESS:
            const newProducts = get(action.payload.data, "data.products", null );
            if ( newProducts && newProducts.length ) {
                const newComponentsArr = state.components.map( component => {
                    if ( component.type === "productBlock" ) {
                        return Object.assign( {},
                            component,
                            { data: {
                                ...component.data,
                                products: [...component.data.products, ...newProducts]
                            }}
                        )
                    }
                    else return component;
                });
                return {
                    ...state,
                    components: newComponentsArr
                }
            } else {
                return state
            }
        default:
            return state;
    }
};

const initialState = {
    components: null
};

export default PortalReducer;