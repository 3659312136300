import * as types from "../actions/actionTypes";

const dmsIntervalsReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.GET_DMS_APPROVED_INTERVAL_SUCCESS:
            return {
                ...state,
                approvedIntervalId: action.payload
            }
        case types.GET_DMS_APPROVED_INTERVAL_FAIL:
            return {
                ...state,
                isDmsReservationFailed: action.payload
            }
        default:
            return state;
    }
};

const initialState = {
    isDmsReservationFailed: false,
    approvedIntervalId: null,
};

export default dmsIntervalsReducer;