// Core
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'initial',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: 76,
    position: 'relative',
    alignItems: 'flex-start',
    marginTop: 50,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: 30,
    },
    [theme.breakpoints.down('xl')]: {
      gridGap: 16,
      marginTop: 24,
    },
  },
  left: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
