// Parts
import { createAction } from '@reduxjs/toolkit';

export type postRepairStatusAsyncTypes = {
  'receiptNumber': string;
};

const repairStatusAsyncAction = Object.freeze({
  postRepairStatusAsync: createAction<postRepairStatusAsyncTypes>('POST_REPAIR_STATUS_ASYNC'),
});

export const {
  postRepairStatusAsync,
} = repairStatusAsyncAction;
