import * as types from "../actions/actionTypes";

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FOCUS_PAYMENT_FIELD:
            return { ...state, focusedField: action.payload };

        case types.GET_PAYMENT_METHODS_SUCCESS:
            return { ...state, ...action.payload };

        case types.GET_PAYMENT_ADDITIONALS_SUCCESS:
            return { ...state, ...{additionals: action.payload} };

        case types.SET_INITIAL_PAYMENT_METHOD_ALIAS:
            return { ...state, ...{initialPaymentMethodAlias: action.payload} };

        case types.GET_PAYMENT_METHOD_BY_ALIAS_SUCCESS:
            return {
                ...state, 
                ...{
                    methods: state.methods.map(method => {
                        if (method.alias === action.payload.alias) {
                            method.data = action.payload.data;
                        }
                        return method;
                    })
                }
            };

        case types.TOGGLE_PAYMENT_METHOD:
            return {
                ...state, 
                ...{
                    methods: state.methods.map(method => {
                        method.active = method.alias === action.payload;
                        return method;
                    }),
                    activeMethodAlias: action.payload
                }
            };

        case types.GET_CREDIT_FORM_BY_TYPE_CODE_SUCCESS:
            // return {
            //     ...state, 
            //     ...{
            //         methods: state.methods.map(method => {
            //             if (method.alias === "paymentCredit") {
            //                 return {
            //                     ...method,
            //                     ...{
            //                         data: {
            //                             ...method.data,
            //                             ...{
            //                                 banks: method.data.banks.map(bank => {
            //                                     if (bank.alias === action.payload.alias) {
            //                                         bank.form = action.payload.data;
            //                                     }
            //                                     return bank;
            //                                 })
            //                             }
            //                         }
            //                     }
            //                 };
            //             }
            //             return method;
            //         })
            //     }
            // };

            return state;

        case types.SET_PAYMENT_ADDITIONAL:
            return {
                ...state,
                ...{
                    additionals: {
                        ...state.additionals,
                        ...{
                            activeStates: {
                                ...state.additionals.activeStates,
                                ...{
                                    [action.payload.name]: action.payload.value,
                                }
                            }
                        }
                    }
                }
            };
        default:
            return state;
    }
};

const initialState = null;

export default paymentReducer;